import { Controller } from 'react-hook-form'

import Input from '@/components/Form/Vanilla/Input'

import useInput from '../Text/hooks'

const OTPField = ({
  name,
  label,
  control,
  className,
  defaultValue,
  rules = null,
  type = 'text',
  disabled = false,
  onChange = (e) => e,
  ...props
}) => {
  const { inputRef } = useInput()
  const validRules = rules ? { rules } : {}

  return (
    <Controller
      {...validRules}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        // eslint-disable-next-line no-unused-vars
        field: { ref: _, onChange: formChange, ...field },
        fieldState: { error }
      }) => (
        <Input.Default
          label={label}
          className={className}
          type={type}
          mask="999999"
          disabled={disabled}
          message={error?.message}
          onChange={(name, value, event) => {
            inputRef.current.value = value
            formChange(event)
            onChange(name, value, event)
          }}
          {...field}
          {...props}
        />
      )}
    />
  )
}

export default OTPField
