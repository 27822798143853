'use client'

import Image from 'next/image'

import { Container } from '@/components'

import Efx from '/public/illustrations/efx.svg'
import Powering from '/public/illustrations/powering.svg'

import { CONTACT_NUMBERS, LINKS } from './constants'

import { SocialMedia, Links } from './components'

import styles from './Footer.module.scss'

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footer__links}>
          {LINKS.map(({ category, links }) => (
            <Links
              key={`${category}-links`}
              category={category}
              links={links}
            />
          ))}
        </div>

        <hr className={styles.footer__divider} />

        <div className={styles.footer__infos}>
          <div>
            <h4 className={styles.info__label}>Boa Vista Serviços S.A.</h4>
            <p className={styles.info__text}>CNPJ 11.725.176/0001-27</p>
            <p className={styles.info__text}>
              Av. Tamboré, 267 - 15º andar - Torre Sul, Barueri, SP - CEP
              06460-000
            </p>
            <p className={styles.info__disclaimer}>
              Não há atendimento presencial nesse endereço
            </p>
          </div>

          <div>
            <div className={styles.footer__infosGroup}>
              {CONTACT_NUMBERS.map(({ label, phone_number }) => (
                <div key={`footer-phone-${phone_number}`}>
                  <h4 className={styles.info__label}>{label}</h4>
                  <p className={styles.info__text}>{phone_number}</p>
                </div>
              ))}
            </div>

            <SocialMedia />
          </div>
        </div>

        <hr className={styles.footer__divider} />

        <div className={styles.footer__address}>
          <picture>
            <Image src={Efx} alt="Equifax - BoaVista" />
          </picture>
          <p className={styles.footer__addressText}>
            Copyright 2024 Equifax Inc., Atlanta, Geórgia. Todos os direitos
            reservados.
          </p>
          <picture>
            <Image src={Powering} alt="Powering the world with knowledge" />
          </picture>
        </div>
      </Container>
    </footer>
  )
}
