import styles from './SkeletonLoader.module.scss'

const Single = ({ height = 48, top = 8 }) => {
  return (
    <div className={styles.skeletonLoader}>
      <div
        style={{ height: `${height}px`, marginTop: `${top}px` }}
        className={styles.skeletonLoader__single}
      />
    </div>
  )
}

const Step = ({ customClass }) => {
  return (
    <div id="step-loader" className={`${styles.skeletonLoader} ${customClass}`}>
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

const SkeletonLoader = { Single, Step }

export { SkeletonLoader }
