/* eslint-disable enforce-ids-in-jsx/unique-ids */
import { Button, ButtonWhatsapp, Icon, If } from '@/components'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { CALLCENTER_PHONE_NUMBER } from '@/constants/phones'

import styles from './ContactButtons.module.scss'

export const ContactButtons = ({
  text = 'Caso não tenha recebido ou prefira continuar o atendimento por telefone clique no botão abaixo.',
  showDesktop = true,
  buttonWppID,
  buttonCallcenterID
}) => {
  const isDesktop = useMediaQuery('screen and (min-width: 768px)')

  return (
    <div className={styles.contactButtons}>
      <Icon name="chat" size="small" color="primary" />

      <If
        condition={isDesktop && showDesktop}
        renderIf={
          <>
            <p className={styles.contactButtons__description}>
              Caso não tenha recebido ou prefira continuar o atendimento por
              telefone, ligue para o número <b>{CALLCENTER_PHONE_NUMBER}</b>
            </p>

            <ButtonWhatsapp
              id={buttonWppID}
              variant="primary"
              iconColor="white"
              customClass={styles.contactButtons__whatsapp}
            >
              Falar no WhatsApp
            </ButtonWhatsapp>
          </>
        }
        renderElse={
          <>
            <p className={styles.contactButtons__description}>{text}</p>

            <ButtonWhatsapp
              id={buttonWppID}
              variant="primary"
              iconColor="white"
              customClass={styles.contactButtons__whatsapp}
            >
              Falar no WhatsApp
            </ButtonWhatsapp>

            <Button
              tagName="a"
              rel="noreferrer"
              variant="secondary"
              id={buttonCallcenterID}
              href={`tel:${CALLCENTER_PHONE_NUMBER}`}
              className={styles.contactButtons__callCenterButton}
            >
              <Icon name="phone-plus" size="small" />
              Ligar no {CALLCENTER_PHONE_NUMBER}
            </Button>
          </>
        }
      />
    </div>
  )
}
