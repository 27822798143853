import classnames from 'classnames'

import { Container } from '..'

import styles from './Banner.module.scss'

export const Banner = ({
  text,
  align,
  children,
  isFixed,
  isAnimated,
  hasBorder,
  hideTextOnMobile
}) => {
  return (
    <div
      className={classnames(styles.banner, {
        [styles[`banner--${align}`]]: align,
        [styles['banner--fixed']]: isFixed,
        [styles['banner--withBorder']]: hasBorder,
        [styles['banner--animated']]: isAnimated
      })}
    >
      <Container classname={styles.banner__wrapper}>
        <p
          className={classnames(styles.banner__text, {
            [styles['hideOnMobile']]: hideTextOnMobile
          })}
        >
          {text}
        </p>

        {children}
      </Container>
    </div>
  )
}
