import { Icon } from '..'

import styles from './NewModal.module.scss'

export const NewModal = ({
  children,
  color,
  customClass,
  section,
  title,
  isAnimated,
  onClose
}) => {
  return (
    <div className={styles.modal} data-overlay="true">
      <div
        className={`
          ${styles.modal__content} 
          ${styles[`modal__content--${color}`]}
          ${isAnimated && styles['modal__content--animated']} ${customClass}
        `}
      >
        {(title || onClose) && (
          <div className={styles.modal__header}>
            {title && <p className={styles.modal__title}>{title}</p>}

            {onClose && (
              <button
                id={`${section}-close-button`}
                onClick={onClose}
                className={styles.modal__closeButton}
              >
                <Icon name="equifax-close" size="small" />
              </button>
            )}
          </div>
        )}

        {children}
      </div>
    </div>
  )
}
