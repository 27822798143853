import _validators from '@/utils/validators'

export const validators = {
  cnpj: {
    required: 'Campo obrigatório',
    validate: (value) => _validators.cnpj(value) || 'CNPJ inválido'
  },
  name: {
    required: 'Campo obrigatório',
    validate: (value) => _validators.name(value) || 'Nome inválido'
  },
  email: {
    required: 'Campo obrigatório',
    validate: (value) => _validators.email(value) || 'Email inválido'
  },
  phone: {
    required: 'Campo obrigatório',
    validate: (value) => _validators.phone(value) || 'Telefone inválido'
  }
}
