import classnames from 'classnames'

import { If, Loader } from '..'

import styles from './Button.module.scss'

export const Button = ({
  tagName = 'button',
  children,
  id,
  size,
  variant,
  className = '',
  type = 'button',
  disabled = false,
  isLoading,
  ...props
}) => {
  const CustomTag = tagName

  const buttonClasses = classnames(styles.button, {
    [`${className}`]: className,
    [`${styles[`button--${size}`]}`]: size,
    [`${styles[`button--${variant}`]}`]: variant,
    [`${styles['button--disabled']}`]: disabled || isLoading
  })

  return (
    <CustomTag
      id={id}
      type={type}
      disabled={disabled || isLoading}
      className={buttonClasses}
      {...props}
    >
      <If condition={isLoading} renderIf={<Loader />} renderElse={children} />
    </CustomTag>
  )
}
