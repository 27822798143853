'use client'

import { useRef, useEffect } from 'react'

const useCheckbox = () => {
  const inputRef = useRef({ value: null })
  const element = inputRef?.current?.inputElement
  const defaultValue = element?.defaultValue

  useEffect(() => {
    const input = inputRef?.current?.inputElement
    const active = document.activeElement

    if (input !== active && defaultValue) {
      input.value = defaultValue
    }
  }, [defaultValue])

  return { inputRef }
}

export default useCheckbox
