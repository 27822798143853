import classnames from 'classnames'

import styles from './MenuToggler.module.scss'

export const MenuToggler = ({ isOpen, setIsOpen }) => {
  return (
    <button
      id="header-menuToggler-button"
      aria-label="Icon de abrir e fechar menu no mobile"
      onClick={() => setIsOpen(!isOpen)}
      className={classnames(styles.menuToggler, {
        [styles['menuToggler--open']]: isOpen
      })}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
  )
}
