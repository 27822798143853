import classnames from 'classnames'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { Container, If } from '@/components'

import { NavigationButtons, NavigationLinks, WorldwideSitesToggler } from '..'

import styles from './Navigation.module.scss'

export const Navigation = ({
  navigationLinks,
  headerNavigationLinks,
  setIsMenuOpen,
  setIsWorldwideSitesVisible
}) => {
  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  const closeMenu = () => setIsMenuOpen(false)

  return (
    <Container classname={classnames(styles.navigation)}>
      <If
        condition={isMobile}
        renderIf={
          <div>
            <NavigationLinks
              navigationLinks={navigationLinks}
              headerNavigationLinks={headerNavigationLinks}
              closeMenu={closeMenu}
            />

            <WorldwideSitesToggler
              color="black"
              arrowPosition="right"
              onClick={() => setIsWorldwideSitesVisible((prev) => !prev)}
            />
          </div>
        }
        renderElse={
          <NavigationLinks
            navigationLinks={navigationLinks}
            headerNavigationLinks={headerNavigationLinks}
            closeMenu={closeMenu}
          />
        }
      />

      <NavigationButtons closeMenu={closeMenu} />
    </Container>
  )
}
