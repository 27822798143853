import { useRef } from 'react'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

export const useFilters = ({ activeFilterIndex, setActiveFilterIndex }) => {
  const filtersRef = useRef()

  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  const onSelectFilter = (index) => {
    const nextVisibleFilter = filtersRef.current.children[index]

    if (!nextVisibleFilter) return

    const { left } = nextVisibleFilter.getBoundingClientRect()

    filtersRef.current.scrollBy({
      left: left - 48,
      behavior: 'smooth'
    })

    setActiveFilterIndex(index)
  }

  const onClickController = (direction) => {
    const directions = {
      left: activeFilterIndex - 1,
      right: activeFilterIndex + 1
    }

    const nextVisibleFilter = filtersRef.current.children[directions[direction]]

    if (!nextVisibleFilter) return

    const { left } = nextVisibleFilter.getBoundingClientRect()

    filtersRef.current.scrollBy({
      left: left - 16,
      behavior: 'smooth'
    })

    setActiveFilterIndex(directions[direction])
  }

  return {
    filtersRef,
    isMobile,
    onSelectFilter,
    onClickController
  }
}
