import classnames from 'classnames'

import { Icon } from '@/components'

import styles from './Action.module.scss'

export const Action = ({
  id,
  text,
  leftIcon,
  rightIcon,
  isExpanded,
  onClick
}) => {
  return (
    <button
      id={id}
      className={styles.action}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        onClick()
      }}
    >
      {leftIcon && <Icon size="xs" name={leftIcon} />}
      {text}
      <Icon
        size="xs"
        name={rightIcon}
        className={classnames(styles.action__arrow, {
          [styles['action__arrow--up']]: isExpanded
        })}
      />
    </button>
  )
}
