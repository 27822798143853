import classnames from 'classnames'

import styles from './styles.module.scss'

const Radio = ({ name, value, checked, customClass, onChange }) => {
  return (
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      className={classnames(styles.radio, customClass)}
      onChange={(event) => onChange(name, event?.target?.value)}
    />
  )
}

export default Radio
