'use client'

import classnames from 'classnames'

import { Icon } from '@/components'

import styles from './Toast.module.scss'

const ICONS = {
  success: 'equifax-check',
  error: 'equifax-error-warning'
}

export const Toast = ({ type, title, text, isVisible, onClickAction }) => {
  return (
    <div
      className={classnames(styles.toast, {
        [styles[`toast--visible`]]: isVisible,
        [styles[`toast--${type}`]]: type
      })}
    >
      <div className={styles.toast__icon}>
        <Icon name={ICONS[type]} color="white" size="small" />
      </div>

      <div className={styles.toast__textContainer}>
        <p className={styles.toast__text}>
          <b>{title}</b>
        </p>
        <p className={styles.toast__text}>{text}</p>
      </div>

      <button className={styles.toast__close} onClick={onClickAction}>
        <Icon color="black" size="xxs" name="close" />
      </button>
    </div>
  )
}
