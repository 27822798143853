import { forwardRef } from 'react'

import removeSpecialChars from '@equifax-ui/utils/formatters/removeSpecialChars'
import { removeWhiteSpaces } from '@equifax-ui/utils/formatters/removeWhiteSpaces'

import Input from '../Default'

const Phone = forwardRef(
  (
    props,
    _ // eslint-disable-line no-unused-vars
  ) => {
    const cellphoneMask = '99 99999-9999'
    const phoneMask = '99 9999-99999'

    const phoneSize = removeWhiteSpaces(removeSpecialChars(props?.value)).length

    const mask = phoneSize >= 11 ? cellphoneMask : phoneMask

    return <Input {...props} mask={mask}></Input>
  }
)

export default Phone
