'use client'

import Image from 'next/image'

import classnames from 'classnames'

import Logo from '/public/icons/logo.svg'

import { ButtonWhatsapp, Container, If } from '@/components'

import { POSITIVE_CONSUMER_URL } from '@/constants/links'

import {
  WorldwideSitesToggler,
  WorldwideSites,
  Navigation,
  MenuToggler
} from './components'

import { useNewHeader } from './useNewheader'

import styles from './Newheader.module.scss'

export const Newheader = () => {
  const {
    sourceURL,
    subheaderLinks,
    navigationLinks,
    headerNavigationLinks,
    headerContentHeight,
    isOrganic,
    isMobile,
    isMenuOpen,
    isWorldwideSitesVisible,
    setIsMenuOpen,
    setIsWorldwideSitesVisible
  } = useNewHeader()

  return (
    <header
      className={classnames(styles.header, {
        [styles['header--open']]: isMenuOpen
      })}
    >
      <Container classname={styles.header__wrapper}>
        <MenuToggler isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />

        <a href={sourceURL} rel="noreferrer" className={styles.header__logo}>
          <Image
            priority
            width={168}
            height={24}
            src={Logo}
            alt="Equifax | BoaVista - Logo"
          />
        </a>

        <If condition={!isOrganic}>
          <ButtonWhatsapp
            variant="none"
            id="header-whatsapp-button"
            aria-label="Falar com especialista no whatsapp"
            customClass={styles.header__whatsappButton}
          />
        </If>

        <div className={styles.header__desktopTogglers}>
          <div className={styles.header__categories}>
            {headerNavigationLinks.map(({ id, category }) => {
              const CustomTag = id === 'business' ? 'p' : 'a'

              return (
                <CustomTag
                  target="_blank"
                  rel="noreferrer"
                  href={POSITIVE_CONSUMER_URL}
                  key={`header-${id}-category`}
                  className={`${styles['header__category']} ${id === 'business' && styles['header__category--unlinked']}`}
                >
                  <span>{category}</span>
                </CustomTag>
              )
            })}
          </div>

          <WorldwideSitesToggler
            arrowPosition="right"
            isWorldwideSitesVisible={isWorldwideSitesVisible}
            onClick={() => setIsWorldwideSitesVisible((prev) => !prev)}
          />
        </div>
      </Container>

      <nav className={styles.header__navigation}>
        {subheaderLinks.map(({ label, path, target, rel }, index) => (
          <a
            rel={rel}
            href={path}
            target={target}
            className={styles.navigation__link}
            id={`header-${path}-link--${index}`}
            key={`header-${path}-link--${index}`}
          >
            {label}
          </a>
        ))}
      </nav>

      <div
        data-overlay={isMenuOpen && isMobile}
        className={classnames(styles.header__content, {
          [styles['header__content--open']]: isMenuOpen
        })}
        style={{
          height: headerContentHeight
        }}
      >
        <Navigation
          navigationLinks={navigationLinks}
          headerNavigationLinks={headerNavigationLinks}
          setIsMenuOpen={setIsMenuOpen}
          setIsWorldwideSitesVisible={setIsWorldwideSitesVisible}
        />

        <WorldwideSites
          isWorldwideSitesVisible={isWorldwideSitesVisible}
          onClickToggler={() => setIsWorldwideSitesVisible((prev) => !prev)}
        />
      </div>
    </header>
  )
}
