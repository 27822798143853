import styles from './styles.module.scss'

const Textarea = ({ customClass, name, register }) => {
  return (
    <textarea
      id={name}
      {...register(name)}
      className={`${styles.textarea} ${customClass}`}
    />
  )
}

export default Textarea
