import classnames from 'classnames'

import styles from './styles.module.scss'

import { useExperiment } from 'rvbr-www/red-testing'
import { useState, useEffect } from 'react'

const Link = ({ pages, onChangePage, setIsOpen }) => {
  const { variant } = useExperiment('form_v2')
  const [shouldShowPlans, setShouldShowPlans] = useState(false)

  const links = shouldShowPlans
    ? pages
    : pages.filter((link) => link.path !== 'plans')

  useEffect(() => {
    if (variant?.id === 'b') {
      setShouldShowPlans(true)
    }
  }, [variant])

  return (
    <>
      {links?.map((page, index) => {
        return (
          <li
            id={`${page.path}-link`}
            className={classnames(`${styles.link} --gtm-click`)}
            key={`header-page-${page?.title}-${index}`}
            onClick={() => {
              onChangePage(page?.path, index)
              setIsOpen(false)
              document.body.style.overflow = 'auto'
            }}
          >
            {page?.title}
          </li>
        )
      })}
    </>
  )
}

export default Link
