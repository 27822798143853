import { Badge, Button, ButtonWhatsapp, Icon, If } from '..'

import { useListPlanCard } from './useListPlanCard'

import styles from './ListPlanCard.module.scss'

export const ListPlanCard = ({ plan, customClass }) => {
  const { isLoading, CTAProps } = useListPlanCard()

  return (
    <div className={`${styles.plan} ${customClass}`}>
      <div>
        <If condition={plan.is_best_selling}>
          <Badge
            round
            color="red"
            text="Mais vendido"
            icon="equifax-stars"
            customClass={styles.plan__bestSelling}
          />
        </If>

        <div className={styles.plan__icon}>
          <Icon size="xs" color="primary" name="equifax-finance-chip" />
          {plan.label}
        </div>

        <p className={styles.plan__highlightText}>{plan.highlight_text}</p>
        <p className={styles.plan__text}>
          {plan.id === 'custom'
            ? 'Para quem busca planos mais amplos.'
            : plan.text}
        </p>
      </div>

      <div>
        <If condition={plan.discount}>
          <Badge
            color="green"
            customClass={styles.plan__discount}
            text={`Economize ${plan.discount} por consulta`}
          />
        </If>

        <If
          condition={plan.id === 'custom'}
          renderIf={
            <ButtonWhatsapp variant="primary" id="postpaid-whatsapp-button">
              Falar com especialista
            </ButtonWhatsapp>
          }
          renderElse={
            <>
              <Button
                isLoading={isLoading}
                {...CTAProps(plan)[plan.type]}
                id={`${plan.type}-wantToHire-button--${plan.id}`}
              >
                {CTAProps(plan)[plan.type].text}
              </Button>
            </>
          }
        />
      </div>
    </div>
  )
}
