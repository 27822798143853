'use client'

import { RoundIcon, SliderController } from '@/components'

import { useFilters } from './useFilters'

import styles from './Filters.module.scss'

export const Filters = ({
  variant = 'primary',
  section,
  filters = [],
  activeFilterIndex,
  setActiveFilterIndex
}) => {
  const { filtersRef, isMobile, onSelectFilter, onClickController } =
    useFilters({ activeFilterIndex, setActiveFilterIndex })

  return (
    <div className={`${styles['filters']} ${styles[`filters--${variant}`]}`}>
      <div ref={filtersRef} className={styles.filters__list}>
        {filters.map(({ icon, title }, index) => {
          const isActive = activeFilterIndex === index

          return (
            <button
              key={`${section}-filter-${index}`}
              id={`${section}-filter-button--${index}`}
              className={`${styles.filters__button} ${isActive && styles['filters__button--active']}`}
              onClick={() =>
                activeFilterIndex !== index && onSelectFilter(index)
              }
            >
              {!isMobile && (
                <RoundIcon
                  icon={icon}
                  size="medium"
                  iconColor={isActive ? 'blue' : 'gray'}
                  bgColor={isActive ? 'shadowed' : 'transparent'}
                />
              )}
              {title}
            </button>
          )
        })}
      </div>

      {isMobile && (
        <SliderController
          size="medium"
          variant="tertiary"
          section={section}
          hasIndicators={false}
          length={filters.length}
          visibleElementIndex={activeFilterIndex}
          onClick={onClickController}
        />
      )}
    </div>
  )
}
