'use client'

import { useState } from 'react'

import { If } from '..'

import { Feedback, Grading } from './components'

import styles from './NPS.module.scss'

export const NPS = ({ customClass }) => {
  const [isNPSSent, setIsNPSSent] = useState(false)

  return (
    <div className={`${styles.nps} ${customClass}`}>
      <If
        condition={isNPSSent}
        renderIf={<Feedback />}
        renderElse={<Grading setIsNPSSent={setIsNPSSent} />}
      />
    </div>
  )
}
