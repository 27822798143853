'use client'

import { Card, Icon } from '@/components'
import Checkbox from '@/components/Form/Vanilla/Checkbox'

import * as styles from './PaymentMethod.module.scss'

export const PaymentMethod = ({ checkboxData, text }) => {
  return (
    <Card withBorder size="medium" customClass={styles.paymentMethod}>
      <p className={styles.paymentMethod__title}>Método de pagamento</p>
      <div className={styles.paymentMethod__iconArea}>
        <Icon name="equifax-bill" color="primary" />
        <p className={styles.paymentMethod__title}>Boleto mensal</p>
      </div>

      {!checkboxData && <p>{text}</p>}

      {checkboxData && (
        <Checkbox
          size="small"
          id={checkboxData.id}
          name={checkboxData.name}
          onChange={(_, value) => checkboxData.onChange(value)}
          label={text}
        />
      )}
    </Card>
  )
}
