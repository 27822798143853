import { Card, Container, Icon, Title } from '@/components'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { ADVANTAGES } from './constants'

import styles from './PostpaidAdvantages.module.scss'

export const PostpaidAdvantages = () => {
  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  return (
    <section className={styles.postpaidAdvantages}>
      <Container>
        <Title
          align="left"
          text="Vantagens que só o plano Pós-pago oferece para sua empresa:"
        />

        <div className={styles.postpaidAdvantages__list}>
          {ADVANTAGES.map(({ icon, title, text }, index) => (
            <Card size="large" withShadow key={`advantage-${index}`}>
              <div className={styles.postpaidAdvantages__title}>
                <Icon
                  name={icon}
                  color="primary"
                  size={isMobile ? 'small' : 'medium'}
                />
                <p>{title}</p>
              </div>

              <p className={styles.postpaidAdvantages__text}>{text}</p>
            </Card>
          ))}
        </div>
      </Container>
    </section>
  )
}
