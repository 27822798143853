export const SELECT_MESSAGES = {
  required: 'Campo obrigatório',
  emptyMessage: 'Nenhuma opção encontrada',
  messageIsSearching: 'Buscando...',
  generalError: 'Um erro ocorreu ao consultar os dados',
  minLength: (value) => `digite ao menos ${value} caracteres para fazer a busca`
}

export const DEFAULT_FILTER_OPTIONS = ({ options, search }) =>
  options.filter((option) =>
    String(option?.label)
      .toLocaleLowerCase()
      .includes(String(search).toLocaleLowerCase())
  )
