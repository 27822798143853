import { Icon } from '..'

import * as styles from './Caption.module.scss'

export const Caption = ({ icon, text }) => {
  return (
    <div className={styles.caption}>
      <Icon size="xs" color="red" name={icon} />
      <small className={styles.caption__text}>{text}</small>
    </div>
  )
}
