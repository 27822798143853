import { Icon } from '@/components'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import styles from './HiringSteps.module.scss'

export const HiringSteps = ({ steps, hasLine }) => {
  const isDesktop = useMediaQuery('screen and (min-width: 1024px)')

  return (
    <ol
      className={`${styles.hiringSteps} ${hasLine && styles[`hiringSteps--withLine`]}`}
    >
      {steps.map(({ icon, description }, index) => (
        <li
          key={`card-${icon}-${index}`}
          className={`${styles.hiringSteps__container}`}
        >
          <p className={styles.hiringSteps__number}>{index + 1}</p>

          <div className={styles.hiringSteps__icon}>
            <Icon
              color="primary"
              name={icon}
              size={hasLine && isDesktop ? 'large' : 'medium'}
            />
          </div>

          <p className={styles.hiringSteps__description}>{description}</p>
        </li>
      ))}
    </ol>
  )
}
