import classnames from 'classnames'
import styles from './Controllers.module.scss'

export const Controllers = ({ children, classname }) => {
  return (
    <div
      className={classnames(styles.controllers, {
        [classname]: classname
      })}
    >
      {children}
    </div>
  )
}
