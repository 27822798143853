/* eslint-disable no-unused-vars */
const getValidInputProps = (props = {}) => {
  const {
    children,
    fieldType,
    finishForm,
    hasFieldLabelAnimation,
    isFollowingOutsideValue,
    ...validProps
  } = props

  return validProps
}

export default getValidInputProps
