import { useRef } from 'react'

const useInput = ({ onChange, name }) => {
  const inputRef = useRef({ value: '' })

  const handleChange = (event) => {
    inputRef.current.value = event.target?.value

    return onChange(name, event.target?.value, event)
  }

  const handleResetField = () => {
    handleChange({ target: { value: '' } })
  }

  return {
    inputRef,
    methods: {
      handleChange,
      handleResetField
    }
  }
}

export default useInput
