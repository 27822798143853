import { Controller } from 'react-hook-form'
import SelectSearch from '../../Vanilla/Select'

const SelectSearchField = ({
  name,
  label,
  control,
  className,
  rules = null,
  options = [],
  disabled = false,
  required,
  onChange = (e) => e,
  isFollowingOutsideValue = true,
  ...props
}) => {
  const validRules = rules ? { rules } : {}
  return (
    <Controller
      {...validRules}
      control={control}
      name={name}
      render={({
        // eslint-disable-next-line no-unused-vars
        field: { ref: _, onChange: formChange, ...field },
        fieldState: { error }
      }) => {
        return (
          <SelectSearch
            label={label}
            options={options}
            disabled={disabled}
            className={className}
            message={error?.message}
            required={required || rules?.required}
            isFollowingOutsideValue={isFollowingOutsideValue}
            onChange={(name, value, event) => {
              formChange(event)
              onChange(name, value, event)
            }}
            {...field}
            {...props}
          />
        )
      }}
    />
  )
}

export default SelectSearchField
