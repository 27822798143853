export const PAYMENT_DETAILS_EXAMPLE = [
  { text: <b>Exemplo:</b> },
  { icon: 'hands', text: 'Plano Contratado' },
  { icon: 'equifax-money-bill', text: 'R$ 99,90' },
  {
    icon: 'equifax-file-analysis-2',
    text: '09  Consultas'
  },
  { icon: 'equifax-list-search', text: 'Relatório básico de CNPJ' },
  {
    icon: 'equifax-finance-chip',
    text: 'R$ 10,29 cada'
  }
]

export const PAYMENT_DETAILS_EXAMPLE_UNMODIFIED = [
  { text: <b>Se você fez:</b> },
  { icon: 'equifax-file-analysis-2', text: '0 Consulta ou' },
  { icon: 'equifax-file-analysis-2', text: '01 à 09 Consultas' }
]

export const PAYMENT_DETAILS_EXAMPLE_UNMODIFIED_RESULT = [
  { text: <b>Você vai pagar:</b> },
  { icon: 'equifax-money-bill', text: 'R$ 99,90' }
]

export const PAYMENT_DETAILS_EXAMPLE_MODIFIED = [
  { text: <b>Se você fez:</b> },
  { icon: 'equifax-file-analysis-2', text: '13 Consultas' }
]

export const PAYMENT_DETAILS_EXAMPLE_MODIFIED_RESULT = [
  { text: <b>Você vai pagar:</b> },
  { icon: 'equifax-money-bill', text: 'R$ 133,77' },
  { icon: 'equifax-finance-chip', text: '13x de R$ 10,29 ' }
]
