'use client'

import styles from './Action.module.scss'

export const Action = ({ isVisible, children }) => {
  return (
    <div
      className={`${styles.action} ${isVisible && styles['action--visible']}`}
    >
      {children}
    </div>
  )
}
