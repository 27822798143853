import classnames from 'classnames'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { Container, Icon, If } from '@/components'

import { WorldwideSitesToggler, ExpandableCategory } from '..'

import { WORLDWIDE_SITES } from './constants'

import styles from './WorldwideSites.module.scss'
import Link from 'next/link'

export const WorldwideSites = ({
  isWorldwideSitesVisible,
  onClickToggler = () => {}
}) => {
  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  return (
    <div
      className={classnames(styles.worldwideSites, {
        [styles['worldwideSites--open']]: isWorldwideSitesVisible
      })}
    >
      <If condition={isMobile}>
        <WorldwideSitesToggler
          color="white"
          arrowPosition="left"
          onClick={onClickToggler}
        />
      </If>

      <Container classname={styles.worldwideSites__wrapper}>
        <ul className={styles['worldwideSites__list--mobile']}>
          {WORLDWIDE_SITES.map(({ title, list }) => (
            <li key={`worldwideSites-${title}--mobile`}>
              <ExpandableCategory
                list={list}
                title={title}
                leftIcon="equifax-world"
              />
            </li>
          ))}
        </ul>

        <>
          {WORLDWIDE_SITES.map(({ title, list }, index) => (
            <div
              key={`worldwideSitesCategory-${title}-${index}--desktop`}
              className={`${styles.category} ${styles['worldwideSites__list--desktop']}`}
            >
              <div className={styles.category__region}>
                <Icon name="equifax-world" color="primary" size="small" />
                <p className={styles.category__title}> {title}</p>
              </div>

              <ul>
                {list.map(({ text, languages }, index) => (
                  <li
                    key={`worldwideSites-${title}-${index}--desktop`}
                    className={styles.category__item}
                  >
                    <span>{text}</span>

                    <div className={styles.category__languages}>
                      {languages.map(({ label, url, aria_label }) => (
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          href={url}
                          key={`${label}-${url}`}
                          aria-label={aria_label}
                          id={`header-${url}-link`}
                          className={styles.category__language}
                        >
                          {label}
                        </Link>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </>
      </Container>
    </div>
  )
}
