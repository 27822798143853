import { Icon } from '..'

import styles from './Badge.module.scss'

export const Badge = ({ round, color, text, icon, customClass }) => (
  <div
    className={`
      ${styles.badge} 
      ${styles[`badge--${color}`]} 
      ${round && styles[`badge--round`]} 
      ${customClass}
    `}
  >
    {icon && <Icon name={icon} size="xs" color="white" />}
    {text}
  </div>
)
