import Image from 'next/image'

import { Button, ContactButtons } from '@/components'

import errorImage from '/public/illustrations/proposal-error.svg'

import styles from './StepError.module.scss'

export const StepError = ({ setHasError }) => {
  return (
    <div className={styles.error}>
      <div className={styles.error__details}>
        <Image
          alt="Ilustração de erro"
          width={248}
          height={153}
          src={errorImage}
          className={styles.error__image}
        />
        <h4 className={styles.error__title}>
          Desculpe, não foi possível enviar suas informações neste momento.
        </h4>
        <p className={styles.error__text}>
          Por favor, tente novamente mais tarde
        </p>
      </div>

      <ContactButtons
        showDesktop={false}
        buttonWppID="error-whatsapp-button"
        buttonCallcenterID="error-callCenter-button"
        text="Se preferir agilizar sua contratação entre em contato através de nossos canais de atendimento"
      />

      <Button
        type="button"
        id="error-back-button"
        variant="primary"
        onClick={() => setHasError(false)}
        className={styles.error__backButton}
      >
        Voltar
      </Button>
    </div>
  )
}
