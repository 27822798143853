import Image from 'next/image'

import classnames from 'classnames'

import { Icon } from '@/components'

import BrazilFlag from '/public/icons/brazil-flag.svg'

import styles from './WorldwideSitesToggler.module.scss'

export const WorldwideSitesToggler = ({
  color = 'black',
  arrowPosition,
  isWorldwideSitesVisible,
  onClick = () => {}
}) => {
  const isOrganic = NEXT_PUBLIC_BUILD_TYPE === 'organic' // eslint-disable-line no-undef

  return (
    <button
      id="header-worldwideSitesToggler-button"
      className={classnames(styles.worldwideSitesToggler, {
        [styles[`worldwideSitesToggler--${color}`]]: color,
        [styles[`worldwideSitesToggler--hide`]]: !isOrganic
      })}
      onClick={onClick}
    >
      <div className={styles.worldwideSitesToggler__defaultContent}>
        {arrowPosition === 'left' && (
          <Icon name="arrow-left" size="xs" color={color} />
        )}
        <Image
          src={BrazilFlag}
          alt="Bandeira do Brasil"
          width={28}
          height={28}
        />
        Brasil
      </div>

      {arrowPosition === 'right' && (
        <Icon
          name="arrow-down"
          size="small"
          color={color}
          className={classnames(styles.arrowDown, {
            [styles['arrowDown--up']]: isWorldwideSitesVisible
          })}
        />
      )}
    </button>
  )
}
