import { useState } from 'react'

export const useProposal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const isOrganic = NEXT_PUBLIC_BUILD_TYPE === 'organic' // eslint-disable-line no-undef

  const onCatch = () => {
    setHasError(true)
    setIsLoading(false)
  }

  return {
    isOrganic,
    isLoading,
    hasError,
    setHasError,
    onCatch
  }
}
