import { useState, useEffect } from 'react'

import { useExperiment } from 'rvbr-www/red-testing'

import isClient from '@equifax-ui/utils/browser/isClient'
import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { SOURCE_URL_LABEL } from '@/constants/api'

import {
  HEADER_NAVIGATION,
  NAVIGATION_LINKS,
  POSITIVE_CONSUMER_LINK_DATA
} from './constants'

const headerMobileHeight = 72

export const useNewHeader = () => {
  const [sourceURL, setSourceURL] = useState('/')
  const [navigationLinks, setNavigationLinks] = useState([])
  const [headerNavigationLinks, setHeaderNavigationLinks] = useState([])
  const [screenHeight, setScreenHeight] = useState(headerMobileHeight)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isWorldwideSitesVisible, setIsWorldwideSitesVisible] = useState(false)

  const subheaderLinks = [...navigationLinks, POSITIVE_CONSUMER_LINK_DATA]

  const isMobile = useMediaQuery('screen and (max-width: 1024px)') ?? false
  const { variant } = useExperiment('form_v2')

  const isOrganic = NEXT_PUBLIC_BUILD_TYPE === 'organic' // eslint-disable-line no-undef

  const windowHeight = `${screenHeight - headerMobileHeight}px`
  const headerContentHeight = isMenuOpen ? windowHeight : null

  const setupNavigationLinks = () => {
    const isPostpaidInV2 = !isOrganic && variant.id === 'b'

    const HEADER_NAVIGATION_DATA = HEADER_NAVIGATION.map((path) => {
      if (isPostpaidInV2 && path.id === 'business') {
        return {
          ...path,
          links: [{ label: 'Planos Pós-Pago', path: '#planos' }, ...path.links]
        }
      }

      return path
    })

    const NAVIGATION_DATA = isPostpaidInV2
      ? [{ label: 'Planos Pós-Pago', path: '#planos' }, ...NAVIGATION_LINKS]
      : NAVIGATION_LINKS

    setNavigationLinks(NAVIGATION_DATA)
    setHeaderNavigationLinks(HEADER_NAVIGATION_DATA)
  }

  useEffect(() => {
    const sourceURL =
      (isClient() && JSON.parse(sessionStorage.getItem(SOURCE_URL_LABEL))) ||
      '/'

    setSourceURL(sourceURL)
    setupNavigationLinks()
  }, [])

  useEffect(() => {
    if (!isMenuOpen) setIsWorldwideSitesVisible(false)

    resizeScreen()
  }, [isMenuOpen])

  const resizeScreen = () => {
    if (isMenuOpen && isClient() && window?.innerHeight > 0) {
      setScreenHeight(window.innerHeight)
    }
  }

  return {
    sourceURL,
    subheaderLinks,
    navigationLinks,
    headerNavigationLinks,
    headerContentHeight,
    isOrganic,
    isMobile,
    isMenuOpen,
    isWorldwideSitesVisible,
    setIsMenuOpen,
    setIsWorldwideSitesVisible
  }
}
