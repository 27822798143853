import styles from './Links.module.scss'

export const Links = ({ category, links = [] }) => {
  return (
    <div className={styles.links}>
      <h4 className={styles.links__category}>{category}</h4>

      <ul className={styles.links__list}>
        {links.map(({ url, target, text, id }) => (
          <li key={`${text}-${url}`} className={styles.links__link}>
            <a id={id} href={url} target={target} rel="noreferrer">
              {text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
