import { Fragment } from 'react'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { Divider, If } from '@/components'

import styles from './NavigationLinks.module.scss'

export const NavigationLinks = ({
  navigationLinks,
  headerNavigationLinks,
  closeMenu
}) => {
  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  return (
    <div>
      <If condition={isMobile}>
        <nav className={styles.navigation__links}>
          {headerNavigationLinks.map(({ id, category, links }, index) => (
            <Fragment key={`header-${id}-category--${index}`}>
              <p className={styles.navigation__category}>{category}</p>

              {links.map(({ label, path, target, rel }, index) => (
                <a
                  rel={rel}
                  href={path}
                  target={target}
                  id={`menu-${path}-link--${index}`}
                  key={`menu-${path}-link--${index}`}
                  className={styles.navigation__link}
                  onClick={closeMenu}
                >
                  {label}
                </a>
              ))}

              <Divider />
            </Fragment>
          ))}
        </nav>
      </If>

      <If condition={!isMobile}>
        <nav className={styles.navigation__links}>
          {navigationLinks.map(({ label, path, target, rel }, index) => (
            <a
              rel={rel}
              href={path}
              target={target}
              className={styles.navigation__link}
              id={`header-${path}-link--${index}`}
              key={`header-${path}-link--${index}`}
              onClick={closeMenu}
            >
              {label}
            </a>
          ))}
        </nav>
      </If>
    </div>
  )
}
