import styles from './Grade.module.scss'

export const Grade = ({ index, customClass, register }) => {
  return (
    <input
      type="radio"
      value={index}
      id={`nps-grade-${index}`}
      aria-label={`Nota ${index} de 5`}
      className={`${styles.grade} ${customClass} --gtm-click`}
      {...register('grade', { required: true })}
    />
  )
}
