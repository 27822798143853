import { useState } from 'react'

import Link from 'next/link'

import classnames from 'classnames'

import { Icon } from '@/components'

import styles from './ExpandableCategory.module.scss'

export const ExpandableCategory = ({ title, list, leftIcon }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <button
        id={`header-category-${title}`}
        className={styles.expandableCategory}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.expandableCategory__titleArea}>
          {leftIcon && <Icon name={leftIcon} color="primary" size="small" />}
          <p>{title}</p>
        </div>

        <Icon
          name="arrow-down"
          size="small"
          className={classnames(styles.expandableCategory__arrowDown, {
            [styles['expandableCategory__arrowDown--up']]: isOpen
          })}
        />
      </button>

      <ul
        className={classnames(styles.expandableCategory__list, {
          [styles['expandableCategory__list--open']]: isOpen
        })}
      >
        {list.map(({ text, languages }, index) => (
          <li
            key={`header-category-${text}--${index}`}
            className={styles.expandableCategory__item}
          >
            <span className={styles.expandableCategory__content}>{text}</span>

            {languages && (
              <div className={styles.expandableCategory__languages}>
                {languages.map(({ label, url, aria_label }) => (
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href={url}
                    key={`${label}-${url}`}
                    aria-label={aria_label}
                    id={`header-${url}-link`}
                    className={styles.expandableCategory__language}
                  >
                    {label}
                  </Link>
                ))}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}
