import classnames from 'classnames'

import styles from './styles.module.scss'

const Menu = ({ isOpen, setIsOpen, className }) => {
  return (
    <button
      aria-label="Menu mobile"
      id="header-menu-button"
      className={classnames(styles.menu, {
        [`${styles['menu--active']}`]: isOpen,
        [className]: className
      })}
      onClick={() => {
        document.body.style.overflow = isOpen ? 'auto' : 'hidden'
        setIsOpen(!isOpen)
      }}
    >
      <div className={styles.menu__line1}></div>
      <div className={styles.menu__line2}></div>
      <div className={styles.menu__line3}></div>
    </button>
  )
}

export default Menu
