export const DEFAULT_ERROR_DATA = {
  type: 'error',
  title: 'Ocorreu um erro inesperado',
  text: 'Por favor, tente novamente mais tarde.'
}

export const ERRORS = {
  403: {
    type: 'error',
    title: 'Código inválido!',
    text: 'Por favor, verifique o código enviado por e-mail e tente novamente.'
  }
}
