import { Icon } from '@/components'

import styles from './Feedback.module.scss'

export const Feedback = () => {
  return (
    <>
      <Icon
        name="equifax-smile"
        color="primary"
        size="small"
        className={styles.feedback__icon}
      />

      <p className={styles.feedback__title}>
        Obrigado por fornecer seu feedback!
      </p>
      <p className={styles.feedback__text}>
        Sua opinião é fundamental para nos ajudar a melhorar nossos serviços.
      </p>
    </>
  )
}
