import isClient from '@equifax-ui/utils/browser/isClient'

import {
  TRACR_ANONYMOUS_ID_LABEL,
  TRACR_SESSION_ID_LABEL
} from '@/constants/api'

import { newRelicNotifyError } from './trackingNewRelic'

export const getTracrAnonymousId = async () => {
  if (!isClient()) return

  try {
    const valueFromStorage = localStorage.getItem(TRACR_ANONYMOUS_ID_LABEL)

    if (!valueFromStorage) return undefined

    return valueFromStorage
  } catch (error) {
    console.error(error)
    newRelicNotifyError(`Error getTracrAnonymousId: ${error}`)
    return undefined
  }
}

export const getTracrSessionId = async () => {
  if (!isClient()) return

  try {
    const valueFromStorage = sessionStorage.getItem(TRACR_SESSION_ID_LABEL)

    if (!valueFromStorage) return undefined

    return valueFromStorage
  } catch (error) {
    console.error(error)
    newRelicNotifyError(`Error getTracrSessionId: ${error}`)
    return undefined
  }
}
