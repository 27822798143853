'use client'

import { useEffect } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { useForm } from 'react-hook-form'

import { useExperiment } from 'rvbr-www/red-testing'

import removeSpecialChars from '@equifax-ui/utils/formatters/removeSpecialChars'
import { removeWhiteSpaces } from '@equifax-ui/utils/formatters/removeWhiteSpaces'

import {
  newRelicButtonInteracted,
  newRelicFormInteracted
} from '@/utils/trackingNewRelic'

import _validators from '@/utils/validators'

import { createLead } from '@/services/steps'

import { useProposalContext } from '@/context/ProposalContext'

import { useAnalytics } from '@/hooks/useAnalytics'
import { useStepHandler } from '@/hooks/useStepHandler'
import { triggerTracrTrack } from '@/hooks/useTracking'

import { TOKEN_LABEL } from '@/constants'
import { STEPS } from '@/constants/steps'

export const useSoftlead = ({ origin, basePayload, onCatch }) => {
  const { leadData, isLoading, setIsLoading, setLeadData } =
    useProposalContext()

  const { variant, successfulExperiment } = useExperiment('form_v2')
  const { onFormFinish } = useStepHandler()
  const { getTrackingData } = useAnalytics()

  const router = useRouter()
  const pathname = usePathname()

  const isSoftleadRoute = pathname.includes(STEPS.softlead)

  const {
    control,
    formState: { isValid },
    handleSubmit,
    getValues,
    reset
  } = useForm({
    mode: 'onChange'
  })

  useEffect(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (isSoftleadRoute) reset(leadData)
  }, [leadData])

  const onEmailBlur = () => {
    const payload = getValues()

    newRelicFormInteracted('Email')

    if (_validators.email(payload.email)) {
      triggerTracrTrack('formInteracted', payload)
    }
  }

  const onSubmit = async (data) => {
    if (origin === 'hero') {
      sessionStorage.setItem('bvs-plan', '')
    }

    const payload = {
      ...data,
      ...basePayload,
      cnpj: removeSpecialChars(data.cnpj),
      phone: removeWhiteSpaces(removeSpecialChars(data.phone))
    }

    newRelicButtonInteracted('Softlead Submit')
    createSoftlead(payload)
  }

  const createSoftlead = async (payload) => {
    setIsLoading(true)

    try {
      const flow = variant?.id === 'b' ? '?flow-key=flow-post-paid-a' : ''
      const trackingData = await getTrackingData()

      const {
        data: { next_step = 'softlead', form_finished = false, auth, data }
      } = await createLead(
        {
          ...payload,
          analytics: trackingData
        },
        flow
      )

      setLeadData((prev) => ({ ...prev, ...data }))
      successfulExperiment()

      if (auth) sessionStorage.setItem(TOKEN_LABEL, JSON.stringify(auth))
      if (form_finished) onFormFinish()

      router.push(`/formulario/${STEPS[next_step]}`)
    } catch (err) {
      onCatch && onCatch()
      setIsLoading(false)
    }
  }

  return {
    control,
    variant,
    isValid,
    isLoading,
    onEmailBlur,
    handleSubmit,
    onSubmit
  }
}
