import classnames from 'classnames'

import { Icon } from '@/components'

import styles from './Tab.module.scss'

export const Tab = ({ header }) => {
  return (
    <div className={styles.tab}>
      {header?.data.map(({ icon, title, id, section }, i) => {
        const isActive = i === header.index
        return (
          <button
            className={styles.tab__button}
            id={`${section}-${id}-tab`}
            key={`${title}-${i}`}
            onClick={() => header.onClick(i, id)}
          >
            <div className={styles.tab__header}>
              <Icon name={icon} color={isActive ? 'blue' : 'black'} />
              <div>
                <h4
                  className={classnames(styles['tab__title'], {
                    [`${styles['tab__title--active']}`]: isActive
                  })}
                >
                  {title}
                </h4>
                <div
                  className={classnames(styles['tab__line'], {
                    [`${styles['tab__line--active']}`]: isActive
                  })}
                />
              </div>
              <div
                className={classnames(styles['tab__divider'], {
                  [`${styles['tab__divider--hidden']}`]: i != 0
                })}
              />
            </div>
          </button>
        )
      })}
    </div>
  )
}
