import { Button, ButtonWhatsapp, Icon } from '@/components'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { WHATSAPP_PHONE_NUMBERS } from '@/constants/whatsapp'
import { CALLCENTER_PHONE_NUMBER } from '@/constants/phones'

import styles from './BannerButtons.module.scss'

export const BannerButtons = () => {
  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  return (
    <div className={styles.bannerButtons}>
      <ButtonWhatsapp variant="tertiary" id="banner-whatsapp-button">
        {isMobile ? 'Contrate' : 'Contrate pelo WhatsApp'}
      </ButtonWhatsapp>

      <Button
        tagName="a"
        rel="noreferrer"
        variant="tertiary"
        id="banner-phone-button"
        href={`tel:${WHATSAPP_PHONE_NUMBERS['organic']}`}
      >
        <Icon name="equifax-phone" size="small" />
        {isMobile ? 'Ligar' : `Ligar no ${CALLCENTER_PHONE_NUMBER}`}
      </Button>
    </div>
  )
}
