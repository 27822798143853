'use client'

/* eslint-disable no-undef */
import { useEffect, useState } from 'react'

import isClient from '@equifax-ui/utils/browser/isClient'

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    if (isClient()) {
      const onResize = () => setMatches(window.matchMedia(query).matches)
      onResize()
      window.addEventListener('resize', onResize)

      return () => {
        window.removeEventListener('resize', onResize)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return matches
}

export default useMediaQuery
