import { newRelicNotifyError } from '@/utils/trackingNewRelic'
import { useState, useEffect } from 'react'

const useIcon = ({ icon }) => {
  const [svgPath, setSvgPath] = useState('')
  const [svgComponent, setSvgComponent] = useState('')

  useEffect(() => {
    import(`/public/icons/${icon}.svg`)
      .then((res) => {
        if (res?.default?.src) setSvgPath(res?.default?.src)
      })
      .catch((err) => {
        console.log(err)
        newRelicNotifyError(`Error import useIcon organic: ${err}`)
      })
  }, [icon])

  useEffect(() => {
    !!svgPath &&
      fetch(svgPath)
        .then((response) => response.text())
        .then((data) => {
          setSvgComponent(data)
        })
        .catch((err) => {
          newRelicNotifyError(`Error import useIcon: ${err}`)
        })
  }, [svgPath])

  return {
    svgComponent
  }
}

export default useIcon
