export const WHATSAPP_PHONE_NUMBERS = {
  organic: '5508007017887',
  paid: '551151080249',
  claudia: '5511992431400'
}

export const WHATSAPP_BASE_URL = 'https://api.whatsapp.com/send/?phone='
export const WHATSAPP_URL_PARAMS = `&text=Quero+contratar+servi%C3%A7os+Equifax+BoaVista&type=phone_number&app_absent=0`

export const WHATSAPP_URL_PARAMS_PARSER = {
  [WHATSAPP_PHONE_NUMBERS.paid]: WHATSAPP_URL_PARAMS,
  [WHATSAPP_PHONE_NUMBERS.organic]: WHATSAPP_URL_PARAMS,
  [WHATSAPP_PHONE_NUMBERS.claudia]: `&text=Ol%C3%A1&type=phone_number&app_absent=0`
}
