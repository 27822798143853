import { Icon } from '@/components'

import * as styles from './HighlightText.module.scss'

export const HighlightText = ({ icon, iconSize = 'medium', title, text }) => {
  return (
    <div className={styles.highlightText}>
      <Icon size={iconSize} color="primary" name={icon} />

      <div>
        <p className={styles.highlightText__text}>
          <b>{title}</b>
        </p>

        <p className={styles.highlightText__text}>{text}</p>
      </div>
    </div>
  )
}
