import { If } from '@/components'

import getValidInputProps from '@equifax-ui/utils/fields/getValidInputProps'
import classnames from 'classnames'

import Input from '../Input/index'
import useSelect from './hooks'

import styles from './styles.module.scss'

const SelectSearch = ({
  className,
  message,
  messageType = 'hint',
  defaultValue = '',
  ...props
}) => {
  const {
    field,
    onBlur,
    onFocus,
    fieldId,
    required,
    onKeyDown,
    hideModal,
    isSearching,
    containerId,
    errorMessage,
    options = [],
    onSelectItem,
    onCustomChange,
    hasErrorMessage,
    onRightIconClick
  } = useSelect(props)

  const { name, label, disabled, type, placeholder } = props
  const selectClassNames = classnames(styles['select-search'], {
    [`${styles['select-search-search--disabled']}`]: disabled,
    [`${className}`]: !!className
  })

  const inputProps = {
    ...props,
    disabled,
    label,
    name,
    type,
    placeholder,
    required,
    tabIndex: '0',
    role: 'combobox',
    id: fieldId || name,
    autoComplete: 'off',
    spellCheck: 'false',
    value: field.current.value || defaultValue,
    onBlur,
    onFocus,
    messageType,
    onKeyDown,
    onChange: onCustomChange,
    'aria-autocomplete': 'list',
    'aria-owns': `option-list-${fieldId}`,
    ...(options.length > 0 && {
      'aria-activedescendant': `option-${fieldId}_0`
    }),
    'aria-expanded': !!(options && options.length > 0),
    'aria-invalid': hasErrorMessage || 'false',
    ...(hasErrorMessage && { 'aria-describedby': `error-${name}` }),
    'aria-labelledby': `label-${name}`
  }

  return (
    <div id={containerId} className={selectClassNames}>
      <div
        className={classnames(styles['select-search-search__field'], {
          [`${styles['select-search-search--invalid']}`]: !!message
        })}
      >
        <Input.Default
          {...getValidInputProps(inputProps)}
          onRightIconClick={onRightIconClick}
          isIconVisible
          placeholder={placeholder}
          onBlur={onBlur}
          messageType={errorMessage && 'error'}
          message={errorMessage}
        />

        <If
          condition={isSearching}
          renderIf={<div className={styles['select-search__loading']}></div>}
        />
      </div>
      <div
        id={`option-list-${fieldId}`}
        className={classnames(styles['select-search__option-list'], {
          [`${styles['select-search__option-list--hidden']}`]: hideModal
        })}
      >
        <div className={styles['select-search__wrapper']}>
          <ul
            id={`results-${fieldId}`}
            className={styles['select-search__result-list']}
            role="listbox"
            tabIndex={0}
            onKeyDown={onKeyDown}
          >
            {options &&
              options.map((option, index) => (
                <li
                  id={`option-${fieldId}_${index}`}
                  className={styles['select-search__item']}
                  onClick={() => onSelectItem(option)}
                  key={index}
                  role="option"
                  aria-selected="false"
                  hidden={!!option?.disabled}
                  aria-disabled={!!option?.disabled}
                >
                  {option?.label}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SelectSearch
