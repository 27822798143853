import { forwardRef } from 'react'
import MaskedInput from 'react-input-mask'

import getValidInputProps from '@equifax-ui/utils/fields/getValidInputProps'

const InputWithoutMask = forwardRef((props, ref) => (
  <input ref={ref} {...props} id={props?.id} />
))

const InputMask = ({ mask, inputRef, isValid, placeholder, ...props }) => {
  const inputInfo = {
    autoCorrect: 'off',
    autoComplete: 'off',
    id: props.name,
    'aria-invalid': !isValid,
    'aria-labelledby': props.name
  }

  if (!mask)
    return (
      <InputWithoutMask
        {...inputInfo}
        {...getValidInputProps(props)}
        ref={inputRef}
        placeholder={placeholder}
      />
    )

  return (
    <MaskedInput
      mask={mask}
      maskChar=""
      disabled={props.disabled}
      value={props.value}
      alwaysShowMask={false}
      onChange={props.onChange}
      onBlur={props.onBlur}
      placeholder={placeholder}
    >
      {(inputProps) => (
        <input ref={inputRef} {...inputProps} {...props} id={props?.id} />
      )}
    </MaskedInput>
  )
}

export default InputMask
