import classnames from 'classnames'

import { If, Icon } from '@/components'

import { useDocPicker } from './useDocPicker'

import styles from './DocPicker.module.scss'

export const DocPicker = ({
  name,
  classname,
  hasFileOnInit,
  onUploadFinish,
  onRemoveFile
}) => {
  const {
    docRef,
    uploadData,
    uploadStatus,
    errorMessage,
    onUploadFile,
    removeFile
  } = useDocPicker({ name, hasFileOnInit, onUploadFinish, onRemoveFile })

  return (
    <div
      className={classnames({
        [classname]: !!classname
      })}
    >
      <div
        className={classnames(styles['document'], {
          [`${styles['document--error']}`]: errorMessage
        })}
      >
        <input
          id={name}
          name={name}
          ref={docRef}
          type="file"
          accept=".pdf,.png,.jpg"
          className={styles['document__field']}
          onInput={onUploadFile}
        />
        <label htmlFor={name} className={styles['document__label']}>
          <Icon
            name={uploadData[uploadStatus].icon}
            size="small"
            color={uploadStatus === 'finish' ? 'green' : 'primary'}
          />

          <div className={styles['document__holder']}>
            <span
              className={classnames(styles['document__message'], {
                [`${styles['document__message--progress']}`]:
                  uploadStatus === 'loading'
              })}
            >
              {uploadData[uploadStatus].message}
            </span>

            <If
              condition={uploadStatus === 'loading'}
              renderIf={<div className={styles['document__progress']} />}
            />
          </div>
        </label>
        <If
          condition={uploadStatus === 'finish'}
          renderIf={
            <button
              className={styles.document__removeFileButton}
              onClick={removeFile}
            >
              <Icon name="trash" color="black" />
            </button>
          }
        />
      </div>
      <span className={styles['document__errorMessage']}>{errorMessage}</span>
    </div>
  )
}
