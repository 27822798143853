import { useState } from 'react'

const useClickWithScroll = () => {
  const [itemIndex, setItemIndex] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const onClickItem = (path, index) => {
    setItemIndex(index)
    scrollTo(path)
  }

  const scrollTo = (path) => {
    // wait for the element to appear in the DOM before scrolling
    const elementInterval = setInterval(() => {
      const element = document.getElementById(path)
      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset
        const offsetPosition = elementPosition

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })
        clearInterval(elementInterval)
      }
    }, 100)
  }

  return {
    scrollTo,
    itemIndex,
    onClickItem,
    menu: {
      isOpen: isMenuOpen,
      setIsOpen: setIsMenuOpen
    }
  }
}

export default useClickWithScroll
