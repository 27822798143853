import { ButtonWhatsapp, NewModal, Divider, Input } from '@/components'

import { useWhatsapp } from '@/hooks/useWhatsapp'

import _validators from '@/utils/validators'

import { POSITIVE_CONSUMER_URL } from '@/constants/links'
import { SAC_PHONE_NUMBER } from '@/constants/phones'

import { usePaywall } from './usePaywall'

import styles from './Paywall.module.scss'

const cnpjValidation = {
  required: 'Campo obrigatório',
  validate: (value) => _validators.cnpj(value) || 'CNPJ inválido'
}

export const Paywall = () => {
  const { control, isValid, onClickWhatsapp, closeModal } = usePaywall()
  const { goToWhatsapp } = useWhatsapp()

  return (
    <NewModal
      section="paywall"
      title="Para continuar informe o CNPJ da sua empresa"
      onClose={closeModal}
    >
      <form>
        <Input.Cnpj
          id="cnpj"
          name="cnpj"
          label="CNPJ da minha empresa"
          placeholder="XX.XXX.XXX/0001-XX"
          control={control}
          rules={cnpjValidation}
        />

        <ButtonWhatsapp
          hasIcon={false}
          disabled={!isValid}
          id="paywall-whatsapp-button"
          aria-label="Falar com especialista"
          onClickWhatsapp={onClickWhatsapp}
        >
          Contratar pelo WhatsApp
        </ButtonWhatsapp>
      </form>

      <Divider customClass={styles.paywall__divider} />

      <div>
        <p className={styles.paywall__text}>
          Não tem CNPJ e quer consultar seu CPF?{' '}
          <a
            target="_blank"
            rel="noreferrer"
            id="paywall-positiveConsumer-link"
            href={POSITIVE_CONSUMER_URL}
            onClick={closeModal}
          >
            Clique aqui
          </a>
        </p>

        <p className={styles.paywall__text}>
          Já é cliente? Fale com o SAC:{' '}
          <a
            rel="noreferrer"
            id="paywall-sac-link"
            href={`tel:${SAC_PHONE_NUMBER}`}
            onClick={closeModal}
          >
            {SAC_PHONE_NUMBER}
          </a>
        </p>

        <button
          id="paywall-continueWithoutCnpj-button"
          className={styles.paywall__noCnpjButton}
          onClick={() => {
            goToWhatsapp()
            closeModal()
          }}
        >
          Seguir sem CNPJ
        </button>
      </div>
    </NewModal>
  )
}
