import classnames from 'classnames'

import { If } from '../If/If'

import styles from './TagImage.module.scss'

export const TagImage = ({
  children,
  hasLines = true,
  lineDirection = 'horizontal',
  lineSize = 'medium',
  lineColor = 'white',
  hasDot = false,
  classname
}) => (
  <div
    className={classnames(styles['tagImage'], {
      [classname]: classname
    })}
  >
    <label className={styles.tagImage__container}>{children}</label>
    <If condition={hasLines}>
      <div
        className={classnames(styles['line__container'], {
          [styles[`line__container--${lineDirection}`]]: lineDirection
        })}
      >
        <div
          className={classnames(styles['line'], {
            [styles[`line__size--${lineSize}`]]: lineSize,
            [styles[`line__color--${lineColor}`]]: lineColor
          })}
        />
        <div
          className={classnames(styles['line'], {
            [styles[`line__container--${lineDirection}--fill`]]: lineDirection
          })}
        />
        <If condition={hasDot}>
          <div
            className={classnames(styles['tagImage__dot'], {
              [styles[`tagImage__dot--${lineColor}`]]: lineColor
            })}
          />
        </If>
      </div>
    </If>
  </div>
)
