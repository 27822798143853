'use client'

import { useEffect, useRef, useState } from 'react'
import { newRelicNotifyError } from '@/utils/trackingNewRelic'

export const useDocPicker = ({
  name,
  hasFileOnInit,
  onUploadFinish,
  onRemoveFile
}) => {
  const [file, setFile] = useState()
  const [uploadStatus, setUploadStatus] = useState('pending')
  const [errorMessage, setErrorMessage] = useState()

  const docRef = useRef()

  const uploadData = {
    pending: {
      message: 'Adicionar arquivo',
      icon: 'equifax-attachment'
    },
    loading: { message: file?.name, icon: 'equifax-upload' },
    finish: { message: file?.name, icon: 'equifax-success-circle' }
  }

  const validFileTypes = ['pdf', 'png', 'jpg', 'jpeg']

  useEffect(() => {
    const initialStatus = hasFileOnInit ? 'finish' : 'pending'

    setUploadStatus(initialStatus)
  }, [hasFileOnInit])

  useEffect(() => {
    file && submitFile()
  }, [file])

  const onUploadFile = () => {
    const uploadedFile = docRef.current.files[0]
    const limitSize = 30000000 // 30MB

    if (!uploadedFile) return

    if (uploadedFile.size > limitSize) {
      setErrorMessage('O arquivo deve possuir o tamanho máximo de 30MB')
      removeFile()
      return
    }

    if (!validFileTypes.includes(uploadedFile.type.split('/')[1])) {
      setErrorMessage('O arquivo deve ser um PDF, PNG ou JPG')
      removeFile()
      return
    }

    setErrorMessage('')
    setFile(uploadedFile)
    setUploadStatus('loading')
  }

  const submitFile = async () => {
    try {
      onUploadFinish && onUploadFinish(name)

      setUploadStatus('finish')
    } catch (err) {
      removeFile()
      setUploadStatus('pending')
      setErrorMessage(
        'Ocorreu um erro ao enviar o arquivo, tente novamente mais tarde'
      )
      newRelicNotifyError(`Erro ao enviar arquivo: ${err}`)
    }
  }

  const removeFile = () => {
    docRef.current.value = null

    setFile(null)
    setUploadStatus('pending')

    onRemoveFile && onRemoveFile(name)
  }

  return {
    docRef,
    uploadData,
    uploadStatus,
    errorMessage,
    onUploadFile,
    removeFile
  }
}
