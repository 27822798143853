import { useEffect } from 'react'
import { usePathname, useRouter } from 'next/navigation'

import { useExperiment } from 'rvbr-www/red-testing'

import { getStepData, submitStep } from '@/services/steps'

import { newRelicNotifyError } from '@/utils/trackingNewRelic'

import { useProposalContext } from '@/context/ProposalContext'

import { TOKEN_LABEL } from '@/constants'
import { SOURCE_URL_LABEL } from '@/constants/api'
import { FEEDBACK_STEPS, STEPS } from '@/constants/steps'
import { DEFAULT_ERROR_DATA, ERRORS } from '@/constants/errors'

export const useStepHandler = () => {
  const {
    previousStep,
    leadData,
    setIsLoading,
    setLeadData,
    setPreviousStep,
    setIsContactButtonVisible,
    updateToast
  } = useProposalContext()

  const router = useRouter()
  const pathname = usePathname()

  const { variant } = useExperiment('form_v2')

  const currentStep = Object.keys(STEPS).find((name) =>
    pathname.includes(STEPS[name])
  )

  const isFeedbackStep = FEEDBACK_STEPS.includes(currentStep)

  useEffect(() => {
    if (isFeedbackStep) setIsLoading(false)
  }, [])

  const redirectStep = (stepName) => {
    const stepRoute = STEPS[stepName]

    if (!stepRoute) {
      goBackHome()
      return
    }

    router.push(`/formulario/${stepRoute}`)
  }

  const onFormFinish = () => {
    sessionStorage.removeItem(TOKEN_LABEL)
  }

  const onError = (err) => {
    const httpStatusCode = err?.status
    const nextStep = err?.data?.error?.data?.next_step

    if (httpStatusCode === 401) {
      onFormFinish()
      goBackHome()
      return
    }

    if (nextStep) {
      redirectStep(nextStep)
      return
    }

    const errorData = ERRORS[httpStatusCode] || DEFAULT_ERROR_DATA

    updateToast(errorData)
    setIsLoading(false)
  }

  const getInitialValues = async () => {
    setIsLoading(true)

    const tokenFromStorage = sessionStorage.getItem(TOKEN_LABEL)

    if (!tokenFromStorage) {
      if (currentStep === 'softlead') {
        setIsLoading(false)
        return
      }

      goBackHome()
      return
    }

    try {
      const flow = variant?.id === 'b' ? '?flow-key=flow-post-paid-a' : ''

      const {
        data: { data = {}, previous_step }
      } = await getStepData(currentStep, flow)

      setLeadData(data)
      setPreviousStep(previous_step)

      window.scroll({
        top: 0,
        behavior: 'smooth'
      })

      setIsLoading(false)
    } catch (err) {
      newRelicNotifyError(`Error getInitialValues: ${err}`)
      onError(err?.response)
    }
  }

  const goNextStep = async (payload) => {
    try {
      const flow = variant?.id === 'b' ? '?flow-key=flow-post-paid-a' : ''

      const {
        data: { next_step = 'softlead', data = leadData, form_finished = false }
      } = await submitStep(currentStep, payload, flow)

      setLeadData((prev) => ({ ...prev, ...data }))

      if (form_finished) onFormFinish()

      redirectStep(next_step)
    } catch (err) {
      newRelicNotifyError(`Error goNextStep: ${err}`)
      onError(err?.response)
    }
  }

  const goPreviousStep = () => {
    setIsLoading(true)

    if (previousStep) {
      redirectStep(previousStep)
      return
    }

    goBackHome()
  }

  const goBackHome = async () => {
    const sourceURL = sessionStorage.getItem(SOURCE_URL_LABEL)
    const initialRoute = (sourceURL && JSON.parse(sourceURL)) || '/'

    setLeadData({})
    setIsContactButtonVisible(false)

    router.replace(initialRoute)
  }

  return {
    isFeedbackStep,
    redirectStep,
    onFormFinish,
    getInitialValues,
    goPreviousStep,
    goNextStep,
    goBackHome
  }
}
