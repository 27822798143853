const isOrganic = NEXT_PUBLIC_BUILD_TYPE === 'organic' // eslint-disable-line no-undef

export const SAC_PHONE_NUMBER = '3003 0101'

export const CONSUMER_PHONE_NUMBER = '3003 0201'

export const POSITIVE_SUBSCRIPTION_PHONE_NUMBER = '0800 727 0201'

export const CALLCENTER_PHONE_NUMBER = isOrganic
  ? '0800 701 7887'
  : '0800 722 2291'
