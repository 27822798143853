import { Fragment } from 'react'

import { Divider, Icon } from '@/components'

import styles from './PaymentDetails.module.scss'

export const PaymentDetails = ({ color = 'white', details }) => {
  const iconColor = color === 'red' ? 'white' : 'primary'

  return (
    <div className={`${styles.details} ${styles[`details--${color}`]}`}>
      {details.map(({ icon, text }, index) => (
        <Fragment key={`payment-detail-${index}`}>
          <div className={styles.details__topic}>
            {icon && <Icon size="small" name={icon} color={iconColor} />}
            <p>{text}</p>
          </div>

          {(index === 0 || index === 2) && index + 1 !== details.length && (
            <Divider color={iconColor} />
          )}
        </Fragment>
      ))}
    </div>
  )
}
