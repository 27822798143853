export const WORLDWIDE_SITES = [
  {
    title: 'Américas',
    list: [
      {
        text: 'Brasil',
        languages: [
          {
            label: 'Português',
            url: 'https://www.equifax.com.br/',
            aria_label: 'Brasil - Português'
          }
        ]
      },
      {
        text: 'USA',
        languages: [
          {
            label: 'Inglês',
            url: 'https://www.equifax.com',
            aria_label: 'USA - Inglês'
          }
        ]
      },
      {
        text: 'Canadá',
        languages: [
          {
            label: 'Inglês',
            url: 'https://www.consumer.equifax.ca/en/personal',
            aria_label: 'Canadá - Inglês'
          },
          {
            label: 'Francês',
            url: 'https://www.consumer.equifax.ca/fr/personnel',
            aria_label: 'Canadá - Francês'
          }
        ]
      },
      {
        text: 'México',
        languages: [
          {
            label: 'Espanhol',
            url: 'https://www.equifax.com.mx/es/empresa',
            aria_label: 'México - Espanhol'
          },
          {
            label: 'Inglês',
            url: 'https://www.equifax.com.mx/en/business',
            aria_label: 'México - Inglês'
          }
        ]
      },
      {
        text: 'Argentina',
        languages: [
          {
            label: 'Espanhol',
            url: 'https://www.soluciones.equifax.com.ar/',
            aria_label: 'Argentina - Espanhol'
          }
        ]
      },
      {
        text: 'Peru',
        languages: [
          {
            label: 'Espanhol',
            url: 'https://www.equifax.pe',
            aria_label: 'Peru - Espanhol'
          }
        ]
      },
      {
        text: 'Chile',
        languages: [
          {
            label: 'Espanhol',
            url: 'https://soluciones.equifax.cl/',
            aria_label: 'Chile - Espanhol'
          }
        ]
      },
      {
        text: 'Costa Rica',
        languages: [
          {
            label: 'Espanhol',
            url: 'https://www.equifax.co.cr',
            aria_label: 'Costa Rica - Espanhol'
          }
        ]
      },
      {
        text: 'Equador',
        languages: [
          {
            label: 'Espanhol',
            url: 'https://www.equifax.ec',
            aria_label: 'Equador - Espanhol'
          }
        ]
      },
      {
        text: 'El Salvador',
        languages: [
          {
            label: 'Espanhol',
            url: 'https://www.equifax.sv/',
            aria_label: 'El Salvador - Espanhol'
          }
        ]
      },
      {
        text: 'Honduras',
        languages: [
          {
            label: 'Espanhol',
            url: 'https://www.equifax.hn',
            aria_label: 'Honduras - Espanhol'
          }
        ]
      },
      {
        text: 'Paraguai',
        languages: [
          {
            label: 'Espanhol',
            url: 'https://soluciones.equifax.com.py',
            aria_label: 'Paraguai - Espanhol'
          }
        ]
      },
      {
        text: 'Uruguai',
        languages: [
          {
            label: 'Espanhol',
            url: 'https://www.equifax.uy',
            aria_label: 'Uruguai - Espanhol'
          }
        ]
      }
    ]
  },
  {
    title: 'Europa',
    list: [
      {
        text: 'Portugal',
        languages: [
          {
            label: 'Português',
            url: 'https://www.equifax.pt',
            aria_label: 'Portugal - Português'
          }
        ]
      },
      {
        text: 'Reino Unido',
        languages: [
          {
            label: 'Inglês',
            url: 'https://www.equifax.co.uk/',
            aria_label: 'Reino Unido - Inglês'
          }
        ]
      },
      {
        text: 'Espanha',
        languages: [
          {
            label: 'Espanhol',
            url: 'https://soluciones.equifax.es',
            aria_label: 'Espanha - Espanhol'
          }
        ]
      }
    ]
  },
  {
    title: 'Ásia e Oceania',
    list: [
      {
        text: 'Austrália',
        languages: [
          {
            label: 'Inglês',
            url: 'https://www.equifax.com.au',
            aria_label: 'Austrália - Inglês'
          }
        ]
      },
      {
        text: 'Nova Zelândia',
        languages: [
          {
            label: 'Inglês',
            url: 'https://www.equifax.co.nz/',
            aria_label: 'Nova Zelândia - Inglês'
          }
        ]
      },
      {
        text: 'India',
        languages: [
          {
            label: 'Inglês',
            url: 'https://www.equifax.co.in',
            aria_label: 'India - Inglês'
          }
        ]
      }
    ]
  }
]
