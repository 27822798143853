/* eslint-disable no-undef */

export const TOKEN_LABEL = 'bvs-token'
export const AFFILIATE_LABEL = 'bvs-affiliate'
export const DOCUMENT_LABEL = 'bvs-document'

export const env =
  process.env.BUILD_ENV || process.env.NODE_ENV || 'development'

export const IS_PRODUCTION = env === 'production'
