export const NAVIGATION_LINKS = [
  {
    title: 'Planos',
    path: 'plans'
  },
  {
    title: 'Serviços',
    path: 'services'
  },
  {
    title: 'Soluções',
    path: 'portal'
  },
  {
    title: 'Ajuda',
    path: 'help'
  }
]

export const LINK_BUTTON_IS_CLIENT =
  'https://transacional.bvsnet.com.br/login.php?utm_source=lp_rv&utm_medium=ja_sou_cliente'
