import { useEffect, useRef, useState } from 'react'

import mergeDeep from '@equifax-ui/utils/object/mergeDeep'
import throttle from 'lodash.throttle'

import {
  DEFAULT_FILTER_OPTIONS,
  SELECT_MESSAGES as messages
} from './constants'
import isClient from '@equifax-ui/utils/browser/isClient'

const useSelect = ({
  name,
  hasFooter,
  fetchOptions,
  required = false,
  propMessages = {},
  openOnFocus = true,
  minSearchCharacters = 3,
  options: defaultOptions = [],
  filterOptions = DEFAULT_FILTER_OPTIONS,
  messageIsSearching = messages.messageIsSearching,
  ...props
}) => {
  const containerId = `ds-select-search-container-${name}`
  const fieldId = name

  // eslint-disable-next-line no-unused-vars
  const [_, reRender] = useState()
  const [options, setOptions] = useState([])
  const [isFocused, setIsFocused] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [hasClicked, setHasClicked] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [isFirstListElement, setIsFirstListElement] = useState(true)

  const customMessages = mergeDeep(messages, propMessages)

  const field = useRef(props?.ref || { value: null })

  const hasErrorMessage = !!errorMessage
  const isEmptyList = options && options.length === 0

  const onRightIconClick = () => {
    const inputField = document.querySelector(
      `#${String(containerId || '')} .ds-input__field`
    )

    inputField?.focus && inputField.focus()
  }

  const throttledSearch = useRef(
    throttle((search) => {
      if (!fetchOptions) return

      setIsSearching(true)
      setErrorMessage(messageIsSearching)

      fetchOptions({ search })
        .then((results = []) => {
          setOptions(results)
          setIsSearching(false)
          if (results.length === 0 || !results) {
            setErrorMessage(customMessages.emptyMessage)
          } else {
            setErrorMessage('')
          }
        })
        .catch((error) => {
          setIsSearching(false)
          setErrorMessage(customMessages.generalError)
          if (isClient()) {
            window?.newrelic
              ?.interaction()
              ?.setName('ErrorEquifax')
              ?.setAttribute('error', `Error throttledSearch: ${error}`)
              ?.save()
          }
        })
    }, 500)
  ).current

  const searchAndSetMessages = (search = []) => {
    if (search.length >= minSearchCharacters) {
      throttledSearch(search)
    }

    if (search.length === 0) {
      setOptions([])
    }
  }

  const resetSelectField = () => {
    setIsSelected(true)
    setOptions([])
    setErrorMessage('')

    field.current.value = ''
    props.onChange && props.onChange(name, null, null)
    props.onSelectItem && props.onSelectItem(name, null)
  }

  const onCustomChange = (selectName, search) => {
    if (!search) return resetSelectField()

    if (fetchOptions) {
      searchAndSetMessages(search)
    } else {
      const results = filterOptions({ options: defaultOptions, search })
      const hasNotFindedValidOption = options.length < 1

      if (hasNotFindedValidOption) {
        setErrorMessage(customMessages.emptyMessage)
      }
      setOptions(results)
    }
    field.current.value = search
  }

  const onSelectItem = (option) => {
    setIsSelected(true)
    setOptions([])
    setErrorMessage('')
    setHasClicked(false)
    notifyChange(option)
  }

  const notifyChange = (option) => {
    field.current.value = option.label
    props.onChange &&
      props.onChange(name, option, { target: { value: option?.value } })
    props.onSelectItem && props.onSelectItem(name, option)
  }

  const onFocus = (e) => {
    setIsFocused(true)
    setOptions([])
    setHasClicked(true)
    setIsSelected(false)

    setErrorMessage('')

    if (openOnFocus) {
      setOptions(defaultOptions)
    }

    props?.onFocus && props?.onFocus(name, e)
  }

  const onBlur = (e) => {
    setIsFocused(false)

    if (e.relatedTarget && e.relatedTarget.className.includes(`result-list`)) {
      return false
    }

    if (!hasFooter && openOnFocus) {
      setHasClicked(false)
      setOptions([])
    }

    if (field.current.value && isSelected) {
      setHasClicked(false)
      setErrorMessage('')
    }

    if (!field.current.value || !isSelected) {
      field.current.value = ''
      if (required) {
        setErrorMessage(customMessages.required)
      }
    }
  }

  const onKeyDown = (e) => {
    const key = e.key
    const listbox = document?.querySelector(`#results-${fieldId}`)
    const field = document?.querySelector(`#${fieldId}`)

    if (!listbox || !fieldId) return

    const activeElementID = field.getAttribute('aria-activedescendant')
    const activeElement = listbox.querySelector(`#${activeElementID}`)
    const activeCssClass = 'ds-select__item--active'

    if (key === 'ArrowDown' || key === 'ArrowUp') {
      const isNextOrPrevious =
        (activeElement && activeElement.nextSibling) ||
        (activeElement && activeElement.previousSibling)

      const nextElement =
        isNextOrPrevious && !isFirstListElement
          ? activeElement[
              key === 'ArrowDown' ? 'nextSibling' : 'previousSibling'
            ]
          : activeElement

      if (nextElement) {
        const characters = [...listbox.children]
        field.setAttribute('aria-activedescendant', nextElement.id)
        characters.forEach((element) => {
          element.classList.remove(activeCssClass)
          element.setAttribute('aria-selected', false)
        })
        nextElement.setAttribute('aria-selected', true)
        nextElement.classList.add(activeCssClass)
        let optionByFieldId = document.querySelector(`#option-list-${fieldId}`)
        if (optionByFieldId)
          optionByFieldId.scrollTop = nextElement.offsetTop - 120
      }
      setIsFirstListElement(false)
    } else if (key === 'Enter') {
      e.preventDefault()
      if (activeElement) {
        const selectedItemOnEnter = activeElement.id.split('_').pop() || ''
        field.setAttribute('aria-activedescendant', `option-${fieldId}_0`)
        onSelectItem(options[+selectedItemOnEnter])
      }
    }
  }

  const findAValidOptionAndSelectBy = (value, resetOnNotFind = false) => {
    let validOption = defaultOptions.find((option) => option?.value === value)

    if (!validOption && resetOnNotFind)
      validOption = {
        label: '',
        value: ''
      }

    if (validOption) {
      notifyChange(validOption)
      reRender([])
    }
  }

  useEffect(() => {
    return () => {
      throttledSearch.cancel()
    }
  }, [])

  useEffect(() => {
    const valueFromOutside = props?.value || props?.defaultValue

    if (!!valueFromOutside && !field.current.value) {
      findAValidOptionAndSelectBy(valueFromOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions])

  useEffect(() => {
    const valueFromOutside = props?.value || props?.defaultValue

    if (props?.isFollowingOutsideValue) {
      findAValidOptionAndSelectBy(valueFromOutside, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.value, props?.defaultValue])

  return {
    field,
    onBlur,
    fieldId,
    onFocus,
    options,
    required,
    onKeyDown,
    isFocused,
    hasClicked,
    isSelected,
    isEmptyList,
    containerId,
    isSearching,
    errorMessage,
    onSelectItem,
    onCustomChange,
    customMessages,
    hasErrorMessage,
    onRightIconClick,
    closeWithEmptyState: () => setHasClicked(false),
    hideModal: (!hasClicked && hasFooter) || (!hasFooter && isEmptyList)
  }
}

export default useSelect
