import styles from './Indicators.module.scss'

export const Indicators = ({ length, variant, visibleElementIndex }) => {
  return (
    <div className={styles.indicators}>
      {Array.from({ length }, (_, index) => (
        <div
          key={`indicator-${index}`}
          className={`
            ${styles.indicators__item} 
            ${styles[`indicators__item--${variant}`]} 
            ${visibleElementIndex === index && styles['indicators__item--active']}
          `}
        />
      ))}
    </div>
  )
}
