'use client'

import { useState } from 'react'

import { useRouter } from 'next/navigation'

import isClient from '@equifax-ui/utils/browser/isClient'

export const useListPlanCard = () => {
  const [isLoading, setIsLoading] = useState(false)

  const router = useRouter()

  const onSelectPlan = (planId) => {
    setIsLoading(true)
    isClient() && sessionStorage.setItem('bvs-plan', planId)
    router.push('/formulario/dados-basicos')
  }

  const CTAProps = (plan) => ({
    postpaid: {
      text: 'Quero contratar',
      onClick: () => onSelectPlan(plan.id)
    },
    prepaid: {
      tagName: 'a',
      href: plan.url,
      rel: 'noreferrer',
      text: 'Quero recarregar'
    }
  })

  return { isLoading, CTAProps }
}
