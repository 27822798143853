import { forwardRef } from 'react'
import classnames from 'classnames'

import InputMask from './components/InputMask'
import useInput from './hooks'

import styles from './styles.module.scss'
import { Icon, If } from '@/components'

const Input = forwardRef(
  (
    {
      mask,
      name = '',
      label = '',
      value = '',
      iconColor,
      placeholder,
      message = '',
      messageType = 'error',
      type = 'text',
      className = '',
      disabled = false,
      isValid = !message || messageType !== 'error',
      onChange,
      isIconVisible = false,
      onRightIconClick,
      ...props
    },
    _ // eslint-disable-line no-unused-vars
  ) => {
    const { inputRef, methods } = useInput({
      name,
      onChange
    })

    return (
      <div
        className={classnames(styles.input, className, {
          [`${styles['input--invalid']}`]: !isValid,
          [`${styles['input--disabled']}`]: disabled,
          [`${styles['input--not-empty']}`]: !!value,
          [`${styles['input__field-container--search-type']}`]: type == 'search'
        })}
      >
        <label htmlFor={name} className={styles.input__label}>
          {label}
        </label>

        <InputMask
          className={styles.input__field}
          id={name}
          type={type}
          name={name}
          mask={mask}
          value={value}
          isValid={isValid}
          disabled={disabled}
          inputRef={inputRef}
          onChange={methods.handleChange}
          placeholder={placeholder}
          autoComplete="off"
          {...props}
        />
        <If condition={isIconVisible}>
          <button
            id="right-icon-button"
            className={`${styles.input__icon} ${styles['input__icon--right']}`}
            aria-hidden="true"
            type="button"
            onClick={onRightIconClick}
          >
            <Icon color={iconColor} name="arrow-down" size="small" />
          </button>
        </If>
        <span
          className={classnames(styles.input__message, {
            [`${styles['input__message--visible']}`]: message,
            [`${styles['input__message--error']}`]: messageType === 'error'
          })}
        >
          {message}
        </span>
      </div>
    )
  }
)

export default Input
