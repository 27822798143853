import classnames from 'classnames'

import styles from './Title.module.scss'

export const Title = ({ color, text, align, customClass }) => {
  return (
    <h3
      className={classnames(styles.title, {
        [customClass]: customClass,
        [styles[`title--${color}`]]: color,
        [styles[`title--${align}`]]: align
      })}
    >
      {text}
    </h3>
  )
}
