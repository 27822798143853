import useCheckbox from './hooks'
import classnames from 'classnames'
import getValidInputProps from '@equifax-ui/utils/fields/getValidInputProps'

import styles from './styles.module.scss'

const Checkbox = ({
  children,
  value,
  size,
  name = '',
  label = '',
  message = '',
  className = '',
  disabled = false,
  isValid = !message,
  onChange = () => {},
  ...props
}) => {
  const { handleChange } = useCheckbox({ onChange, name })

  return (
    <label
      className={classnames(styles.checkbox, '--gtm-click', className, {
        [`${styles['checkbox--error']}`]: !isValid,
        [`${styles['checkbox--disabled']}`]: disabled
      })}
    >
      <div className={styles['checkbox__check-mark']}>
        <input
          id={name}
          name={name}
          value={value}
          type="checkbox"
          disabled={disabled}
          onChange={handleChange}
          className={styles.checkbox__input}
          {...getValidInputProps(props)}
        />
        <span className={styles['fake-checkbox']}>
          <svg width="12px" height="9px" viewBox="0 0 12 9">
            <polyline points="1 5 4 8 11 1"></polyline>
          </svg>
        </span>
      </div>

      {!!label && (
        <p
          className={classnames(styles.checkbox__label, {
            [styles[`checkbox__label--${size}`]]: size
          })}
        >
          {label}
        </p>
      )}

      {children}
    </label>
  )
}

export default Checkbox
