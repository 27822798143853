import classnames from 'classnames'

import { Icon } from '@/components'

import styles from './Accordion.module.scss'

export const Accordion = ({
  isActiveItem,
  handleTrigger,
  index = 0,
  title,
  content
}) => {
  return (
    <div className={styles.accordion}>
      <button
        id={`accordion-${index}-button`}
        aria-controls={`accordion-${index}-content`}
        aria-expanded={isActiveItem}
        onClick={() => handleTrigger(index)}
        className={classnames(styles.accordion__trigger, {
          [`${styles['accordion__trigger--active']}`]: isActiveItem
        })}
      >
        {title}
        <Icon
          name="arrow-down"
          size="small"
          className={classnames(styles.accordion__icon, {
            [`${styles['accordion__icon--up']}`]: isActiveItem
          })}
        />
      </button>
      <div
        role="region"
        id={`accordion-${index}-content`}
        aria-labelledby={`accordion-${index}-content`}
        aria-hidden={!isActiveItem}
        className={classnames(styles.accordion__content, {
          [`${styles['accordion__content--open']}`]: isActiveItem
        })}
      >
        {content}
      </div>
    </div>
  )
}
