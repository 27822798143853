import { Icon } from '@/components'

import styles from './Controllers.module.scss'

const ICON_COLORS = {
  primary: 'blue',
  secondary: 'white'
}

export const Controllers = ({ size, variant, data }) => {
  const controllerClasses = `
    ${styles.controllers__button} 
    ${styles[`controllers__button--${variant}`]}
    ${styles[`controllers__button--${size}`]}
  `

  return (
    <div className={styles.controllers}>
      <button
        id={data['left'].id}
        disabled={data['left'].disabled}
        onClick={data['left'].onClick}
        aria-label="Voltar para o item anterior"
        className={controllerClasses}
      >
        <Icon name="arrow-left" color={ICON_COLORS[variant]} />
      </button>

      <button
        id={data['right'].id}
        disabled={data['right'].disabled}
        onClick={data['right'].onClick}
        aria-label="Avançar para o próximo item"
        className={controllerClasses}
      >
        <Icon name="arrow-right" color={ICON_COLORS[variant]} />
      </button>
    </div>
  )
}
