export const ADVANTAGES = [
  {
    icon: 'equifax-hands-lock',
    title: 'Sem fidelidade',
    text: 'Cancele nossos serviços a qualquer momento de forma fácil.'
  },
  {
    icon: 'equifax-user-up',
    title: 'Serviço de recuperação',
    text: 'Notifique e Negative devedores para aumentar suas chances de recuperar pagamentos atrasados.'
  },
  {
    icon: 'equifax-bill',
    title: 'Até 45 dias para  pagar',
    text: 'Pagamento com boleto gerado em até 45 dias após contratação.'
  }
]
