import { Icon } from '..'

import styles from './RoundIcon.module.scss'

export const RoundIcon = ({
  size = 'medium',
  icon,
  iconColor,
  bgColor,
  border
}) => {
  const ICON_SIZES = {
    medium: 'small',
    large: 'large'
  }

  return (
    <div
      className={`
        ${styles.roundIcon} 
        ${styles[`roundIcon--${size}`]} 
        ${styles[`roundIcon--${border}`]} 
        ${styles[`roundIcon--${bgColor}`]}
      `}
    >
      <Icon name={icon} color={iconColor} size={ICON_SIZES[size]} />
    </div>
  )
}
