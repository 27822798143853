import { PaymentDetails } from './components'

import {
  PAYMENT_DETAILS_EXAMPLE,
  PAYMENT_DETAILS_EXAMPLE_MODIFIED,
  PAYMENT_DETAILS_EXAMPLE_MODIFIED_RESULT,
  PAYMENT_DETAILS_EXAMPLE_UNMODIFIED,
  PAYMENT_DETAILS_EXAMPLE_UNMODIFIED_RESULT
} from './constants'

import styles from './PaymentExample.module.scss'

export const PaymentExample = () => {
  return (
    <div className={styles.paymentExample}>
      <div>
        <div className={styles.paymentExample__hired}>
          <PaymentDetails details={PAYMENT_DETAILS_EXAMPLE} />
        </div>

        <div className={styles.paymentExample__result}>
          <div>
            <PaymentDetails details={PAYMENT_DETAILS_EXAMPLE_UNMODIFIED} />

            <PaymentDetails
              type="result"
              color="red"
              details={PAYMENT_DETAILS_EXAMPLE_UNMODIFIED_RESULT}
            />
          </div>

          <div>
            <PaymentDetails details={PAYMENT_DETAILS_EXAMPLE_MODIFIED} />

            <PaymentDetails
              type="result"
              color="red"
              details={PAYMENT_DETAILS_EXAMPLE_MODIFIED_RESULT}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
