const getCookieByName = (cookieName) => {
  if (!cookieName) return ''

  const allCookiesString = `; ${document.cookie}`
  const allCookiesArray = allCookiesString.split(`; ${cookieName}=`)

  if (allCookiesArray.length === 2) return allCookiesArray[1].split(';')[0]

  return ''
}

module.exports = getCookieByName
