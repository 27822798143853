import { useEffect, useState } from 'react'

import isClient from '@equifax-ui/utils/browser/isClient'

import { AFFILIATE_LABEL } from '@/constants'

export const useConditionalRendering = () => {
  const [isAffiliate, setIsAffiliate] = useState(true)

  useEffect(() => {
    const isAffiliate =
      isClient() && JSON.parse(sessionStorage.getItem(AFFILIATE_LABEL))

    setIsAffiliate(isAffiliate)
  }, [])

  return { isAffiliate }
}
