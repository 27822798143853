import getCookieByName from '@equifax-ui/utils/cookies/getCookieByName'

import { getSourceUrl } from '@/utils/sourceUrl'
import { getDocumentReferrer } from '@/utils/referrer'
import { getTracrAnonymousId, getTracrSessionId } from '@/utils/tracr'
import { getGa4ClientId } from '@/utils/ga4ClientId'

export const useAnalytics = () => {
  const getFBData = () => ({
    pixel_fbp: getCookieByName('_fbp'),
    pixel_fbc: getCookieByName('_fbc')
  })

  const getGAData = async () => ({
    ga4_client_id: await getGa4ClientId(),
    ga4_cookie: getCookieByName('_ga')
  })

  const getTrackingData = async () => ({
    source_url: await getSourceUrl(),
    referrer_url: await getDocumentReferrer(),
    anonymous_id: await getTracrAnonymousId(),
    session_id: await getTracrSessionId(),
    ...(await getGAData()),
    ...getFBData()
  })

  return {
    getFBData,
    getGAData,
    getTrackingData
  }
}
