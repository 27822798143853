import { Button } from '@/components'
import Textarea from '@/components/Form/Vanilla/Textarea'

import { Grade } from '..'

import { useGrading } from './useGrading'

import styles from './Grading.module.scss'

export const Grading = ({ setIsNPSSent }) => {
  const { isValid, register, handleSubmit, onSubmit } = useGrading({
    setIsNPSSent
  })

  return (
    <form className={styles.grading} onSubmit={handleSubmit(onSubmit)}>
      <p className={styles.grading__title}>
        O que você achou da experiência ao contratar o serviço da Equifax |
        BoaVista?
      </p>

      <div className={styles.grading__grades}>
        <div>
          <p>Muito insatisfeito</p>
          <p>Extremamente satisfeito</p>
        </div>

        {Array.from({ length: 6 }, (_, i) => (
          <Grade
            index={i}
            key={`nps-grade-${i}`}
            customClass={styles.grading__grade}
            register={register}
          />
        ))}
      </div>

      <p className={styles.grading__title}>
        Há algo que poderíamos ter feito para melhorar sua experiência?
      </p>

      <Textarea name="text" register={register} />

      <Button
        type="submit"
        size="small"
        variant="secondary"
        id="conclusion-sendNPS-button"
        disabled={!isValid}
        className={styles.grading__button}
      >
        Enviar
      </Button>
    </form>
  )
}
