'use client'

// Utils
import sanitize from '@equifax-ui/utils/browser/sanitize'
import classnames from 'classnames'

// Hooks
import useIcon from './useIcon'
// Styles
import styles from './Icon.module.scss'

const IconComponent = ({ htmlString, className, color = 'blue', ...props }) => {
  const iconClasses = classnames(styles.icon, {
    [`${className}`]: !!className,
    [`${styles['icon__size--' + props?.size]}`]: !!props?.size,
    [`${styles['icon__background--' + props?.bgcolor]}`]: !!props?.bgcolor,
    [`${styles['icon__background--round']}`]: !!props?.round
  })

  return (
    <span
      {...props}
      data-color={color}
      className={iconClasses}
      dangerouslySetInnerHTML={sanitize(htmlString)}
    />
  )
}

export const Icon = ({ icon = '', name = icon, size = 'medium', ...props }) => {
  const { svgComponent } = useIcon({ icon: name })

  return (
    <IconComponent
      {...props}
      size={size}
      aria-hidden="true"
      htmlString={svgComponent}
    />
  )
}
