/* eslint-disable no-undef */

import { axiosInstance } from './axios'

export const createLead = async (payload, flow = '') => {
  return await axiosInstance.post(`/form/softlead/${flow}`, payload)
}

export const submitStep = async (stepName, payload, flow = '') => {
  return await axiosInstance.post(`/form/${stepName}/${flow}`, payload)
}

export const getStepData = async (stepName, flow = '') => {
  return await axiosInstance.get(`/form/${stepName}/${flow}`)
}

export const sendAction = async (lead_id, route, payload = {}) => {
  return await axiosInstance.post(`/leads/${lead_id}/${route}`, payload)
}

export const updateLead = async (lead_id, route, payload = {}) => {
  return await axiosInstance.put(`/leads/${lead_id}/${route}`, payload)
}
