'use client'

import useClickWithScroll from '@equifax-ui/hooks/useClickWithScroll'

import { useSearchParams } from 'next/navigation'
import Image from 'next/image'

import { Button, Container, ButtonWhatsapp } from '@/components'
import Menu from './components/menu'
import Link from './components/Links'

import { NAVIGATION_LINKS, LINK_BUTTON_IS_CLIENT } from './constants'

import Logo from '/public/icons/logo.svg'
import WhatsappImage from '/public/icons/whatsapp-image.svg'

import styles from './Header.module.scss'
import { useEffect, useState } from 'react'

import classnames from 'classnames'
import isClient from '@equifax-ui/utils/browser/isClient'

export const Header = ({ hiddenMenuDesktop = false }) => {
  const [updateScreenHeight, setUpdateScreenHeight] = useState(56)
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const { onClickItem } = useClickWithScroll()

  const isDesktop = isClient() && window?.innerWidth > 1024
  const searchParams = useSearchParams()
  const params = new URLSearchParams(searchParams.toString())

  const resizeScreen = () => {
    if (isOpenMenu && isClient()) {
      setUpdateScreenHeight(window?.innerHeight)
    }
  }
  useEffect(() => {
    resizeScreen()
  }, [isOpenMenu])

  return (
    <header
      className={classnames(styles.header, {
        isMobileMenuOpen: !isDesktop && isOpenMenu
      })}
      style={{
        height: isOpenMenu ? `${updateScreenHeight}px` : 'auto'
      }}
    >
      <Container>
        <div className={styles.header__desktop}>
          <div className={styles.header__content}>
            <Menu
              isOpen={isOpenMenu}
              setIsOpen={setIsOpenMenu}
              className={styles['header__desktop--hidden']}
            />
            <a
              href={`/?${params.toString()}`}
              rel="noreferrer"
              id="header-logo-image"
              className={styles['header__desktop--logoImage']}
            >
              <Image
                priority
                width={217}
                height={32}
                src={Logo}
                alt="Equifax | BoaVista - Logo"
              />
            </a>

            <ButtonWhatsapp
              id={'mobile-header-whatsapp-button'}
              variant="link"
              hasIcon={false}
              customClass={styles.header__whatsapp}
            >
              <Image
                priority
                width={32}
                height={32}
                src={WhatsappImage}
                alt="Whatsapp logo"
              />
            </ButtonWhatsapp>
          </div>

          <nav
            className={classnames(styles.header__menu, {
              [styles['header__menu--isOpen']]: !isDesktop && isOpenMenu,
              [styles['header__menu--hidden']]: hiddenMenuDesktop
            })}
          >
            <ul className={styles['header__menu--alignCenter']}>
              <Link
                pages={NAVIGATION_LINKS}
                onChangePage={onClickItem}
                setIsOpen={setIsOpenMenu}
              />
            </ul>
            <div
              className={`${styles['header__menu--alignCenter']} ${styles['header__menu--inverterButton']}`}
            >
              <ButtonWhatsapp
                id={'header-whatsapp-button'}
                variant={'whatsapp'}
                iconColor={'white'}
                customClass={styles.header__whatsappDesktop}
              >
                Falar com especialista
              </ButtonWhatsapp>
              <Button
                tagName="a"
                id="header-alreadyClient-button"
                size="medium"
                variant="secondary"
                target="_blank"
                rel="noreferrer"
                href={LINK_BUTTON_IS_CLIENT}
                className={styles['header__menu--minWidth']}
              >
                Já sou cliente
              </Button>
            </div>
          </nav>
        </div>
      </Container>
    </header>
  )
}
