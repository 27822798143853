import { Fragment } from 'react'

export const If = ({
  children,
  condition,
  renderIf = null,
  renderElse = null
}) => {
  const ifElement = children ? children : renderIf

  return <Fragment>{condition ? ifElement : renderElse}</Fragment>
}
