'use client'

import { Controller } from 'react-hook-form'

import useCheckbox from './hooks'
import Checkbox from '../../Vanilla/Checkbox'

const CheckboxField = ({
  name,
  id,
  label,
  control,
  className,
  defaultValue = false,
  rules = null,
  disabled = false,
  onChange = (e) => e,
  children,
  ...props
}) => {
  const { inputRef } = useCheckbox()
  const validRules = rules ? { rules } : {}

  return (
    <Controller
      {...validRules}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        // eslint-disable-next-line no-unused-vars
        field: { ref: _, onChange: formChange, ...field },
        fieldState: { error }
      }) => (
        <Checkbox
          {...field}
          {...props}
          id={id}
          name={name}
          label={label}
          checked={field.value}
          message={error?.message}
          className={className}
          disabled={disabled}
          onChange={(name, value, event) => {
            inputRef.current.value = value
            formChange(event)
            onChange(name, value, event)
          }}
        >
          {children}
        </Checkbox>
      )}
    />
  )
}

export default CheckboxField
