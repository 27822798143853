import isClient from '@equifax-ui/utils/browser/isClient'

export const newRelicNotifyError = (err) => {
  isClient() &&
    window?.newrelic
      ?.interaction()
      ?.setName('EquifaxRequestError')
      ?.setAttribute('ErrorDescription', err)
      ?.save()
  return
}

export const newRelicInteraction = (browserInteractionName) => {
  isClient() &&
    window?.newrelic?.interaction()?.setName(browserInteractionName)?.save()
  return
}

export const newRelicButtonInteracted = (ButtonLabel) => {
  isClient() &&
    window?.newrelic
      ?.interaction()
      ?.setName('EquifaxButtonInteracted')
      ?.setAttribute('ButtonLabel', ButtonLabel)
      ?.save()
  return
}

export const newRelicFormInteracted = (inputName) => {
  isClient() &&
    window?.newrelic
      ?.interaction()
      ?.setName('EquifaxFormInteracted')
      ?.setAttribute('InputName', inputName)
      ?.save()
  return
}
