import { useState, useRef, useEffect } from 'react'

const useCheckbox = ({ onChange, name, initialValue, id }) => {
  const [checked, setChecked] = useState(initialValue)
  const checkboxRef = useRef(null)

  const handleChange = (event) => {
    return onChange(name, event.target?.checked, event)
  }

  useEffect(() => {
    checkboxRef?.current?.checked
      ? setChecked(true) && handleChange({ target: { value: id } })
      : setChecked(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxRef?.current?.checked])

  return {
    handleChange,
    checked,
    checkboxRef,
    setChecked
  }
}

export default useCheckbox
