import { Icon } from '..'

import styles from './HiringTimeline.module.scss'

export const HiringTimeline = ({ steps }) => {
  return (
    <ul className={styles.steps}>
      {steps.map(({ icon, color = 'primary', text }, i) => (
        <li
          key={`step-${icon}-${i}`}
          className={`${styles.step} ${styles[`step--${color}`]}`}
        >
          <div className={styles.step__icon}>
            <Icon size="xs" name={icon} color={color} />
          </div>
          {text}
        </li>
      ))}
    </ul>
  )
}
