import { If } from '@/components'
import { Icon } from '@/components'
import classnames from 'classnames'

import styles from './Modal.module.scss'

export const Modal = ({
  children,
  className,
  titleIcon,
  closeModal,
  iconColor = 'blue',
  title = '',
  size = 'big',
  iconBgcolor,
  iconSize,
  isVisible = true,
  hasCloseIcon = true,
  ...props
}) => {
  return (
    <If condition={isVisible}>
      <div
        className={classnames(styles.modal, {
          [`${styles['modal__size--' + size]}`]: !!size
        })}
        {...props}
      >
        <div
          className={classnames(styles['modal__content'], className, {
            [`${styles['modalmodal__content--with-children']}`]: children
          })}
        >
          <header className={styles.modal__header}>
            <span className={styles['modal__header--title']}>
              <If condition={titleIcon}>
                <Icon
                  color={iconColor}
                  name={titleIcon}
                  bgcolor={iconBgcolor}
                  size={iconSize}
                  className={styles['modal__header--icon']}
                />
              </If>
              {title}
            </span>
            <If condition={hasCloseIcon}>
              <button
                id="modal-close-button"
                aria-label="Fechar o modal"
                onClick={() => closeModal()}
              >
                <Icon
                  name="equifax-close"
                  color={iconColor}
                  className={styles.modal__close}
                />
              </button>
            </If>
          </header>

          {children}
        </div>
      </div>
    </If>
  )
}
