import { Fragment } from 'react'
import classNames from 'classnames'

import { Divider, Icon } from '@/components'

import styles from './Details.module.scss'

export const Details = ({ id, details, isExpanded, openPaymentDetails }) => {
  return (
    <>
      <div
        className={classNames(styles.details, {
          [styles['details--expanded']]: isExpanded
        })}
      >
        <div className={styles.details__content}>
          <p className={styles.details__title}>Produtos disponíveis</p>

          {details?.map((list, index) => (
            <Fragment key={`plan-details-category--${id}-${index}`}>
              {list.map(({ name, value }, i) => (
                <ul
                  key={`plan-details-values--${id}-${i}`}
                  className={styles.details__list}
                >
                  <li
                    className={`${styles.details__item} ${i === 0 && styles['details__item--bold']}`}
                  >
                    <span>{name}</span>
                    <span>{value}</span>
                  </li>
                </ul>
              ))}

              {index + 1 < details.length && <Divider />}
            </Fragment>
          ))}
        </div>

        <button
          type="button"
          className={styles.details__button}
          onClick={openPaymentDetails}
        >
          Entenda o pagamento
          <Icon name="equifax-info" size="xs" />
        </button>
      </div>
    </>
  )
}
