import { forwardRef } from 'react'

import Input from '../Default'

const Cnpj = forwardRef(
  (
    props,
    _ // eslint-disable-line no-unused-vars
  ) => {
    const mask = '99.999.999/9999-99'

    return <Input {...props} mask={mask}></Input>
  }
)

export default Cnpj
