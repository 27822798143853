export const SOURCE_URL_LABEL = `bvs-source-url`

export const REFERRER_LABEL = `bvs-referrer`

export const TRACR_ANONYMOUS_ID_LABEL = 'tracr_anonymous_id'

export const TRACR_SESSION_ID_LABEL = 'tracr_session_id'

export const GA4_CLIENT_ID_LABEL = `bvs-ga4-client-id`

export const PIXEL_FBP = `bvs-token`

export const PIXEL_FBC = `bvs-token`
