import classnames from 'classnames'
import styles from './Container.module.scss'

export const Container = ({ children, classname }) => {
  return (
    <div className={classnames(styles.container, { [classname]: classname })}>
      {children}
    </div>
  )
}
